import { useEffect, useState } from "react";
import { Row, Col, Select, Form, Image } from "antd";
import { TextInputField } from "components/TextInputField";
import { FilterWrapper, FormItemButton, SelectWrapper } from "./style";
import { getToken, IToken } from "services/api";
import { TextButton } from "components/TextButton";
import { RowBetween } from "components/Row";
import { StyledForm } from "components/StyledForm";
import select1 from "assets/vectors/vector-arrow-select-1.svg";
import select2 from "assets/vectors/vector-arrow-select-2.svg";
import close from "assets/icons/close.png";
import FormItem from "antd/lib/form/FormItem";

const { Option } = Select;

export function PoolFilterDrawer({ onApply }) {
  const [form] = Form.useForm();
  const [sellTokens, setSellTokens] = useState([]);
  const [payTokens, setPayTokens] = useState([]);

  const [params, setParams] = useState<IToken>({
    pageIndex: 1,
    pageSize: 6,
    search: undefined,
    tokenAddress: undefined,
  });

  const handleSearchSellToken = async (value: string) => {
    const res = await getToken({ ...params, search: value, pageIndex: 1 });
    if (res) setSellTokens(res?.payload?.data);
  };

  const handleSearchPayToken = async (value: string) => {
    const res = await getToken({ ...params, search: value, pageIndex: 1 });
    if (res) setPayTokens(res?.payload?.data);
  };

  const onFinish = async (values: any) => {
    onApply(values);
  };

  useEffect(() => {
    void (async () => {
      const res = await getToken({ ...params });
      if (res) {
        setSellTokens(res?.payload?.data);
        setPayTokens(res?.payload?.data);
      }
    })();
  }, []);

  const onReset = () => {
    form.resetFields();
  };

  return (
    <FilterWrapper>
      <StyledForm
        onFinish={onFinish}
        autoComplete="off"
        layout={"vertical"}
        form={form}
      >
        <FormItem name={["search"]}>
          <TextInputField placeholder="Search..."></TextInputField>
        </FormItem>

        <FormItem label="Selling amount" name={["minSellAmount"]}>
          <TextInputField
            placeholder="Input amount"
            type="number"
          ></TextInputField>
        </FormItem>

        <Row gutter={[8, 8]}>
          <Col span={12}>
            <FormItem label="Selling token" name={["sellToken"]}>
              <SelectWrapper
                showSearch
                allowClear
                placeholder="Select a token"
                optionFilterProp="children"
                bordered={false}
                dropdownStyle={{ background: "#000" }}
                suffixIcon={<Image src={select1} alt="" preview={false} />}
                onSearch={handleSearchSellToken}
                clearIcon={<Image src={close} alt="" preview={false} />}
              >
                {sellTokens?.map((token) => (
                  <Option
                    key={sellTokens.indexOf(token)}
                    style={{ color: "#fff" }}
                    value={token?.tokenAddress}
                  >
                    {token?.symbol?.toUpperCase()}
                  </Option>
                ))}
              </SelectWrapper>
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem label="Paying token" name={["payToken"]}>
              <SelectWrapper
                showSearch
                allowClear
                placeholder="Select a token"
                optionFilterProp="children"
                bordered={false}
                dropdownStyle={{ background: "#000" }}
                onSearch={handleSearchPayToken}
                suffixIcon={<Image src={select2} alt="" preview={false} />}
                clearIcon={<Image src={close} alt="" preview={false} />}
              >
                {payTokens?.map((token) => (
                  <Option
                    key={payTokens.indexOf(token)}
                    style={{ color: "#fff" }}
                    value={token?.tokenAddress}
                  >
                    {token?.symbol?.toUpperCase()}
                  </Option>
                ))}
              </SelectWrapper>
            </FormItem>
          </Col>
        </Row>

        <FormItem label="Input price" name={["minPrice"]}>
          <TextInputField
            placeholder="Input amount"
            type="number"
          ></TextInputField>
        </FormItem>

        <RowBetween>
          <TextButton onClick={onReset}>Clear</TextButton>
          <FormItemButton>
            <TextButton htmlType="submit">Apply</TextButton>
          </FormItemButton>
        </RowBetween>
      </StyledForm>
    </FilterWrapper>
  );
}
