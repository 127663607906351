import BigConnectWallet from "components/connectWallet/ConnectWallet_big";
import { PoolLayaway } from "constants/routers";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "redux/reducers";
import HomepageTemplate from "template/Homepage"
import { LocalhostStorage } from "utils/sessionStorage";
import logo from "assets/images/logoHomepage.svg"
import vector from "assets/vectors/vector-homepage.svg";
import background from "assets/images/homepage.png";
import { HeadlineStyled, LoginContainer, ButtonWraper, ImgLogo, VectorHomepage, Background } from "./styled";

const Login = () => {
  const navigate = useNavigate();
  const { account } = useActiveWeb3React();
  const { isSignar } = useSelector((state: RootState) => state.signar);
  const pageCurrent = LocalhostStorage.get("pageCurrent")
  useEffect(() => {
    void (async () => {
      const chainId = process.env.REACT_APP_CHAIN_ID; // goerli
      if (window?.ethereum?.networkVersion !== chainId) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "5" }],
          });
        } catch (err) {
          if (err.code === 4902) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: process.env.REACT_APP_CHAIN_NAME,
                  chainId: process.env.REACT_APP_CHAIN_ID,
                  rpcUrls: [process.env.REACT_APP_RPC],
                },
              ],
            });
          }
        }
      }
    })();
  }, []);


  const renderLoginWallet = () => {
    return (
      <>
      <Background src={background} alt="" preview={false} />
      <LoginContainer>
        <ImgLogo src={logo} alt="logo" preview={false} />
        <ButtonWraper>
        <VectorHomepage preview={false} src={vector} alt=""/>
          <BigConnectWallet />
        </ButtonWraper>
      </LoginContainer>
      </>
    );
  };


  return (
    <HomepageTemplate>
      {account && isSignar ? navigate(PoolLayaway[(pageCurrent || 'pool').toUpperCase()]) : renderLoginWallet()}
    </HomepageTemplate>
  );
};

export default Login;
