import { Modal, Row, Image, Button } from "antd";
import styled from "styled-components";
export const CancelModalWrapper = styled(Modal)`
  top: calc(calc(100% - 400px) / 2);

  .ant-modal-content {
    background: #000;
    border: #CFF54D 1px solid;
    border-radius: 10px;
    .ant-modal-body {
    }
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 56px;
`;

export const TitleStyled = styled.p`
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
`;

export const CancelContent = styled.p`
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  @media(min-width: 1400px){
    font-size: 16px;
  }
  @media(min-width: 1600px){
    font-size: 17px;
  }
`;

export const WrapperButton = styled(Row)`
  padding: 0 80px;
`;

export const LineTitle = styled(Image)`
  width: 118px;
  display: block;
  position: absolute;
  bottom: 9px;
  right: -3px;
  height: 20px;
`;

export const ButtonStyled = styled(Button)<any>`
  border-radius: 10px;
  width: 100%;
  border: none;
  font-weight: 700;
  height: 40px;
  display: flex;
  font-size: 16px;
  margin-top: ${p => p.martop || '-23px'};
  background: transparent !important;
  color: ${p => p.color || '#cff54d'} !important;
  justify-content: center;
  align-items: center;
  :disabled,
  :disabled:hover {
    background-color: transparent;
    color: #ccc !important;
    background-image: none !important;
  }
  :hover,
  :focus {
    background: transparent;
    color: #cff54d;
  }
  
`;

export const ButtonBackground = styled(Image)`
  position: relative;
  top: 10px;
`;

export const WrapperButtonCancel = styled.div`
  text-align: center;
  .ant-image {
    height: 0;
  }
  .ant-btn-loading:before {
    display: none !important;
  }
`;

export const UnderLine = styled(Image)`
  width: 55px;
  display: block;
  position: absolute;
  bottom: -32px;
  right: -26px;
  height: 20px;
`;
