import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useCallback } from "react";
import { usePayTokenContract } from "./usePayTokenContract";
import { MAX_UINT_256 } from "constants/value";
import { BigNumber } from "ethers";

const ROUTER_ADDRESS = process.env.REACT_APP_LAYAWAY_ROUTER;

export const usePayTokenCallback = (token: string) => {
  const { account } = useActiveWeb3React();
  const payToken = usePayTokenContract(token);

  const allowance = useCallback(async () => {
    try {
      if (payToken && account) {
        const tx = await payToken.allowance(account, ROUTER_ADDRESS);
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, token]);
  const symbol = useCallback(async () => {
    try {
      if (payToken && account) {
        const tx = await payToken.symbol();
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, token]);

  const balanceOf = useCallback(
    async (account) => {
      try {
        if (payToken && account) {
          const tx = await payToken.balanceOf(account);
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, token]
  );

  const approve = useCallback(async () => {
    try {
      if (payToken && account) {
        const gasLimit = await payToken.estimateGas.approve(
          ROUTER_ADDRESS,
          MAX_UINT_256
        );
        const tx = await payToken.approve(ROUTER_ADDRESS, MAX_UINT_256, {
          gasLimit: BigNumber.from(gasLimit).mul(2),
        });
        return tx.wait(1);
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, token]);

  return { allowance, symbol, balanceOf, approve };
};
