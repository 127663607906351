/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ListNavTop,
  Logo,
  WrapperNavbar,
} from "styles/Common/HeaderDesktop.styled";
import ConnectWallet from "components/connectWallet/ConnectWallet";

import {
  EtherImg,
  HeaderWrapper,
  UserMenuButton,
  WrapperNetwork,
} from "styles/Common/ConnectWallet.styled";
import LogoEther from "assets/icons/ethereum.svg";
import { Col, Image } from "antd";
import {
  LogoTitle,
  LogoWrapper,
  ConnectWalletBtn,
  TextConnectBtn,
  ListWebsitWrapper,
} from "./styled";
import logo from "assets/images/logoHomepage.svg";
import useVisible from "hooks/useVisible";
export default function Header() {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  return (
    <HeaderWrapper>
      <Col ref={ref} xl={5} md={3} xs={0}>
        <ConnectWalletBtn onClick={() => setIsVisible(!isVisible)}>
          <TextConnectBtn>Pool layaway</TextConnectBtn>
        </ConnectWalletBtn>
        {isVisible && (
          <ListWebsitWrapper>
            <a href="/pool-options">Pool options</a>
            <a href="/same-asset-loan">Same asset loan</a>
            <a href="/no-collateral/">No collateral</a>
          </ListWebsitWrapper>
        )}
      </Col>
      <Col xl={14} md={6} xs={7}>
        <LogoWrapper justify="center">
          <Image src={logo} alt="" preview={false} />
        </LogoWrapper>
      </Col>
      <Col xl={5} lg={6} md={8} xs={17}>
        <WrapperNavbar>
          {/* <ListNavTop> */}
          <ConnectWallet />
          <UserMenuButton>
            <WrapperNetwork>
              <EtherImg src={LogoEther} alt="logo" />
              Ethereum
            </WrapperNetwork>
          </UserMenuButton>
          {/* </ListNavTop> */}
        </WrapperNavbar>
      </Col>
    </HeaderWrapper>
  );
}
