import { ColumnCenter } from "components/Column";
import { Tooltip, Image } from "antd";
import { TextButton } from "components/TextButton";
import styled from "styled-components";
import bgPool from "assets/images/texture.png"

export const ItemWrapper = styled(ColumnCenter)`
  background: url(${bgPool});
  background-color: #d9d9d9;
  width: max-content;
  max-width: 246px;
  border-radius: 10px;
  padding: 16px;
  z-index: 1;
  @media (max-width: 820px) and (min-width: 769px) {
    margin-bottom: 6px;
  }
  @media (max-width: 1265px) and (min-width: 1024px){
    max-width: 235px;
  }
`;

export const TitleLabel = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: black;
  margin-top: 8px;
  font-family: "Bicyclette Bold";
`;

export const ValueLabel = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: black;
  /* text-transform: uppercase; */
  margin-top: 4px;
  font-family: "Bicyclette Bold"
`;

export const InfoWrapper = styled(ColumnCenter)`
  width: 100%;
  & > *:not(:first-child) {
    margin-top: 5px;
  }
`;

export const ActionWrapper = styled(ColumnCenter)`
  margin-top: 12px;
  display: flex;
`;

export const Action = styled(TextButton)`
  margin: 0px 4px;
  letter-spacing: 0.05rem;
  font-family: "Bicyclette Bold";
  :disabled,
  :disabled:hover {
    border: #ccc 1px solid !important;
    background-image: linear-gradient(#242526, #000) !important;
    color: white;
  }
`;

export const OverlayBackground = styled.div`
  color: #d9d9d94d;
  width: 100%;
  height: 100%;
`;

export const WrapperTooltip = styled(Tooltip)`
  margin: 0px;
  margin-bottom: 6px;
`;

export const WrapperArea = styled.div`
  width: 200px;
  height: auto;
  padding: 16;
  margin-bottom:12px;

`;

export const ActionEmpty = styled.div`
  height: 32px;
`;

export const IconPool = styled.div`
  background: #000;
  border-radius: 50%;
  width: 30px;
  position: absolute;
  right: 15px;
  top: 18px;
  @media(max-width: 1180px){
    right: 58px;
  }
  @media(max-width: 1024px)  {
    right: 40px;
  }
  @media(max-width: 820px){
    right: 26px
  }
  @media (max-width: 576px){
    right: 72px;
  }
`;

export const IconToken = styled(Image)`
  width: 19px;
  height: 18px;
  margin-right: 5px;
`;