import { Divider } from "antd";
import styled from "styled-components";

export const MenuStyled = styled.div`
  background: #000000;
  box-sizing: border-box;
  border-radius: 18px;
  padding: 1.5em 0em;
  margin-top: 4px;
  * {
    color: #fff;
  }
  .ant-menu {
    background: unset;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
  }
  .ant-menu-item {
    width: 170px;
  }

  .ant-menu-item:active {
    background: transparent;
    color: #CFF54D;
  }
`;

export const StartDivider = styled(Divider)`
  border: 1px solid #3c3c3f;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 46px;
  margin: 8px 0;
`;

export const EndDivider = styled(Divider)`
  border: 1px solid #3c3c3f;
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 30px;
`;

export const CustomSpace = styled.div`
  display: flex;
  align-items: center;
`;

// export const ThemeItem = styled(Item)`
//   margin-top: 20px !important;
// `;
