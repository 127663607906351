import { useState, useEffect, useRef } from "react";
import {
  PaginationStyled,
  TableStyled,
  ValueStyled,
  WrapperAction,
  FilterFormWrapper,
  FilterButton,
  InputWrapper,
  ButtonWrapper,
  IconSearch,
  TableWrapper,
  CheckboxIcon,
  SelectItem,
  TextSelect,
  SubPagination,
  IconFilter,
  SelectAssetPopup,
  FilterTitle,
} from "./styled";
import { PaymentModal } from "components/modal/PaymentModal";
import { CancelModal } from "components/modal/CancelModal";
import OrderButton from "components/button/OrderButton";
import {
  Col,
  InputRef,
  Space,
  PaginationProps,
  Dropdown,
  Menu,
  MenuProps,
  Image,
  Row,
  Select,
} from "antd";
import { SuccessModal } from "components/modal/SuccessModal";
import { getOrder, getToken, IOrder, IToken } from "services/api";
import { convertSecondToDay } from "utils/convertSecondToDay";
import {
  ColumnType,
  FilterConfirmProps,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import { DownOutlined, FilterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { FailedModal } from "components/modal/FailedModal";
import { formatNumber } from "utils/formatNumber";
import { ORDER_STATUS_ENUM } from "constants/enum/OrderStatus";
import { dayTerms } from "constants/value";
import { ORDER_ROLE_ENUM } from "constants/enum/OrderRole";
import { IS_EXPIRE_ENUM } from "constants/enum/IsExpire";
import checkbox from "assets/vectors/vector-checkbox.svg";
import checkbox1 from "assets/vectors/vector-checkbox-2.svg";
import checkbox2 from "assets/vectors/vector-checkbox-3.svg";
import iconPagi1 from "assets/vectors/vector-pagi-1.svg";
import iconPagi2 from "assets/vectors/vector-pagi-2.svg";
import iconPagi3 from "assets/vectors/vector-pagi-3.svg";
import moment from "moment";
import filterIcon from "assets/icons/filter.png";
import { SelectWrapper } from "components/modal/CreatePoolModal/styled";
import { IconToken } from "components/PoolItem/styled";
import { iconDefault } from "utils/defaultIcon";
import useVisible from "hooks/useVisible";
import { RootStateOrAny, useSelector } from "react-redux";
import prvIcon from "assets/icons/prev-icon.svg";
import nextIcon from "assets/icons/next-icon.svg";

export const OrderTable = () => {
  const { account } = useActiveWeb3React();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [poolAddress, setPoolAddress] = useState("");
  const [payingSecondTime, setPayingSecondTime] = useState("");
  const [payingSecondTimeFormat, setPayingSecondTimeFormat] =
    useState<Number>(undefined);
  const [payToken, setPayToken] = useState();
  const [visiblePayment, setVisiblePayment] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [success, setShowSuccess] = useState(false);
  const [contentFilter, setContentFilter] = useState("All");
  const listIcons = [checkbox, checkbox1, checkbox2];
  const listIconsPagi = [iconPagi1, iconPagi2, iconPagi3];

  interface DataType {
    id: string;
    poolAddress: string;
    purchaseAmount: number;
    purchaseAmountFormat: number;
    sellToken: any;
    payToken: any;
    termDaysLimit: number;
    price: number;
    priceFormat: number;
    userAddress: string;
    orderId: string;
    expireDate: number;
    payingSecondTime: string;
    payingSecondTimeFormat: number;
    isExpire: boolean;
  }
  type DataIndex = keyof DataType;
  const [failed, setShowFailed] = useState(false);
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const [current, setCurrent] = useState(1);
  const [totalPage, setTotalPage] = useState<number>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const { Option } = Select;
  const [params, setParams] = useState<IOrder>({
    pageIndex: current,
    pageSize: 7,
    orderId: undefined,
    purchaseAmountFormat: undefined,
    sellToken: undefined,
    payToken: undefined,
    priceFormat: undefined,
    termDaysLimit: undefined,
    minPrice: undefined,
    orderStatus: ORDER_STATUS_ENUM.OPEN,
    payingSecondTimeFormat: undefined,
    isExpire: undefined,
    userAddress: undefined,
    sort: "-createdAt",
  });

  const [tokens, setTokens] = useState([]);
  useEffect(() => {
    void (async () => {
      const res = await getToken({});
      if (res) setTokens(res?.payload?.data);
    })();
  }, []);

  useEffect(() => {
    void (async () => {
      const res = await getOrder(params);

      if (res) {
        setData(res?.payload?.data);
        setTotalPage(res?.payload?.totalCount);
      }
    })();
  }, [params, refresh]);
  const onPageChange: PaginationProps["onChange"] = (currentPage) => {
    setParams({ ...params, pageIndex: currentPage });
    setCurrent(currentPage);
  };

  const handleRefreshOrderTable = () => {
    setRefresh(!refresh);
  };

  const showSuccess = () => {
    setShowSuccess(true);
    setVisiblePayment(false);
    setVisibleCancel(false);
  };
  const showFailed = () => {
    setShowFailed(true);
    setVisiblePayment(false);
    setVisibleCancel(false);
  };
  const handleShowPayment = (
    id: string,
    poolAddress: string,
    payingSecondTime: string,
    payingSecondFormat: number,
    payToken: any
  ) => {
    setVisiblePayment(true);
    setId(id);
    setPoolAddress(poolAddress);
    setPayToken(payToken);
    setPayingSecondTime(payingSecondTime);
    setPayingSecondTimeFormat(payingSecondFormat);
  };

  const handleShowCancelModal = (value: string) => {
    setVisibleCancel(true);
    setId(value);
  };
  const onCloseModal = () => {
    setVisiblePayment(false);
    setVisibleCancel(false);
    setShowSuccess(false);
    setShowFailed(false);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setParams({
      ...params,
      [dataIndex]: selectedKeys[0],
      pageIndex: 1,
    });

    setCurrent(1);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, dataIndex?: DataIndex) => {
    clearFilters();
    setSearchText("");
    setParams({
      ...params,
      [dataIndex]: undefined,
    });
  };

  const handleFilterOrder: MenuProps["onClick"] = async (e) => {
    setContentFilter(e.key);
    try {
      if (e.key === ORDER_ROLE_ENUM.ALL) {
        setParams({
          ...params,
          userAddress: undefined,
          isExpire: undefined,
          pageIndex: 1,
        });
        setCurrent(1);
      } else if (e.key === ORDER_ROLE_ENUM.MY_ORDER) {
        setParams({
          ...params,
          userAddress: account,
          isExpire: IS_EXPIRE_ENUM.NO,
          pageIndex: 1,
        });
        setCurrent(1);
      } else if (e.key === ORDER_ROLE_ENUM.EXPIRED) {
        setParams({
          ...params,
          userAddress: undefined,
          isExpire: IS_EXPIRE_ENUM.YES,
          pageIndex: 1,
        });
        setCurrent(1);
      }
    } catch (e) {
      console.log(e.message);
    }

    return true;
  };

  // get list token
  const { refreshToken } = useSelector(
    (state: RootStateOrAny) => state.refreshToken
  );

  const [listSellTokens, setListSellTokens] = useState([]);
  const [sellTokens, setSellTokens] = useState([]);
  const [total, setTotal] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paramsToken, setParamsToken] = useState<IToken>({
    pageIndex: currentPage,
    pageSize: undefined,
    search: undefined,
    tokenAddress: undefined,
  });

  const handleFilter = (
    selectedValue,
    dataIndex: DataIndex,
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    console.log(selectedValue);
    if (confirm) {
      confirm();
    }
    setParams({
      ...params,
      [dataIndex]: selectedValue[0],
      pageIndex: 1,
    });
  };

  const getColumnSearchProps = (
    dataIndex?: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <FilterFormWrapper>
        <InputWrapper
          ref={searchInput}
          placeholder={`Search...`}
          value={selectedKeys[0]}
          type="number"
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
        />

        <Space>
          <ButtonWrapper
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            size="small"
            className="button-search"
          >
            Search
          </ButtonWrapper>
        </Space>
      </FilterFormWrapper>
    ),
    filterIcon: (filtered: boolean) => (
      <IconSearch
        className="search-icon"
        color={filtered ? "#9600B0" : undefined}
      />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <ValueStyled>{formatNumber(text)}</ValueStyled>
      ) : (
        text
      ),
  });

  useEffect(() => {
    void (async () => {
      const res = await getToken({ ...paramsToken });
      if (res?.payload?.data) {
        setSellTokens(res?.payload?.data);
        setTotal(res?.payload?.totalCount);
        setPageSize(res?.payload?.pageSize);
        setListSellTokens((prv) => [...prv, ...res?.payload?.data]);
      }
    })();
  }, [paramsToken, refreshToken]);

  const handleScroll = (e) => {
    const isEndOfList =
      e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight;
    console.log(listSellTokens.length, total / pageSize, currentPage);

    if (
      listSellTokens.length < total &&
      isEndOfList &&
      total / pageSize >= currentPage
    ) {
      setCurrentPage(currentPage + 1);

      setParamsToken({ ...paramsToken, pageIndex: currentPage + 1 });
    }
  };

  const handleClearToken = async () => {
    const res = await getToken({
      ...params,
      pageIndex: 1,
      pageSize: pageSize,
    });
    if (res) {
      setListSellTokens(res?.payload?.data);
      setCurrentPage(1);
    }
  };

  const getColumnFilter = (dataIndex?: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <FilterFormWrapper>
        <SelectWrapper
          onPopupScroll={handleScroll}
          placeholder="Select a token"
          onChange={(e) => setSelectedKeys(e ? [e.toString()] : [])}
          allowClear
          onClear={handleClearToken}
          value={selectedKeys[0]}
        >
          {listSellTokens?.length === 0
            ? sellTokens?.map((token, index) => (
                <Option
                  ref={ref}
                  key={index}
                  style={{ color: "#000" }}
                  value={token?.tokenAddress}
                  className="select-token"
                >
                  <IconToken
                    fallback={iconDefault}
                    src={token?.img || iconDefault}
                    alt=""
                    preview={false}
                  />
                  {token?.symbol?.toUpperCase()}
                </Option>
              ))
            : listSellTokens?.map((token, index) => (
                <Option
                  key={index}
                  style={{ color: "#000" }}
                  value={token?.tokenAddress}
                  className="select-token"
                >
                  <IconToken
                    fallback={iconDefault}
                    src={token?.img || iconDefault}
                    alt=""
                    preview={false}
                  />
                  {token?.symbol?.toUpperCase()}
                </Option>
              ))}
        </SelectWrapper>
        <Space>
          <ButtonWrapper
            onClick={() => handleFilter(selectedKeys, dataIndex, confirm)}
            size="small"
            className="button-search"
          >
            Filter
          </ButtonWrapper>
        </Space>
      </FilterFormWrapper>
    ),
    // filterIcon: (filtered: boolean) => (
    //   <IconFilter onClick={() => setIsVisible(!isVisible)}>
    //     <img src={filterIcon} alt="filter" />
    //   </IconFilter>
    // ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <ValueStyled>{formatNumber(text)}</ValueStyled>
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Id",
      dataIndex: "orderId",
      key: "orderId",
      width: "12%",
    },
    {
      title: "Purchase amount",
      dataIndex: "purchaseAmountFormat",
      key: "purchaseAmountFormat",
      width: "18%",

      ...getColumnSearchProps("purchaseAmount"),
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: <FilterTitle ref={ref}>Sell token </FilterTitle>,
      dataIndex: "sellToken",
      key: "sellToken",
      width: "15%",
      // filters: arraySellToken?.map((token) => {
      //   return {
      //     text: (
      //       <SelectItem>
      //         <CheckboxIcon
      //           src={listIcons[Math.floor(Math.random() * listIcons.length)]}
      //           alt=""
      //           preview={false}
      //         />
      //         <TextSelect>{token?.symbol}</TextSelect>
      //       </SelectItem>
      //     ),
      //     value: token?.address,
      //   };
      // }),
      ...getColumnFilter("sellToken"),
      render: (value) => <ValueStyled>{value.symbol}</ValueStyled>,
    },
    {
      title: "Paying token",
      dataIndex: "payToken",
      key: "payToken",
      width: "15%",
      ...getColumnFilter("payToken"),
      render: (value) => <ValueStyled>{value.symbol}</ValueStyled>,
    },
    {
      title: "Days term",
      dataIndex: "termDaysLimit",
      key: "termDaysLimit",
      filterMultiple: false,
      filters: dayTerms?.map((term) => {
        return {
          text: (
            <SelectItem>
              <CheckboxIcon
                src={listIcons[Math.floor(Math.random() * listIcons.length)]}
                alt=""
                preview={false}
              />
              <TextSelect className="ps-2">{`${
                convertSecondToDay(term).value
              } DAYS`}</TextSelect>
            </SelectItem>
          ),
          value: term,
        };
      }),
      render: (value) => (
        <>
          {convertSecondToDay(value).value} {convertSecondToDay(value).type}
        </>
      ),
      width: "15%",
    },
    {
      title: "Expired time",
      dataIndex: "expireDate",
      key: "expireDate",
      filterMultiple: false,
      render: (value) => (
        <>{moment.unix(value).format("DD/MM/YYYY hh:mm:ss")}</>
      ),
      width: "15%",
    },
    {
      title: "Price",
      dataIndex: "priceFormat",
      key: "priceFormat",
      width: "8%",

      ...getColumnSearchProps("price"),
      render: (value) => <>{formatNumber(value)}</>,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "17%",
      align: "center",
      render: (record, _) => (
        <>
          <WrapperAction gutter={[16, 16]}>
            <Col span={12}>
              {account?.toLowerCase() === _?.userAddress && !_?.isExpire && (
                <OrderButton
                  action="payment"
                  onClick={() =>
                    handleShowPayment(
                      _?.orderId,
                      _?.poolAddress,
                      _?.payingSecondTime,
                      _?.payingSecondTimeFormat,
                      _?.payToken
                    )
                  }
                  loading={loading}
                  disabled={false}
                >
                  Payment
                </OrderButton>
              )}
            </Col>

            <Col span={12}>
              {_?.isExpire && (
                <OrderButton
                  action="cancel"
                  onClick={() => handleShowCancelModal(_.orderId)}
                  loading={loading}
                  disabled={false}
                >
                  Cancel
                </OrderButton>
              )}
            </Col>
          </WrapperAction>
        </>
      ),
    },
  ];

  const handleTableChange = async (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    setCurrent(1);
    setParams({
      ...params,
      sellToken:
        Object(filters).sellToken !== null
          ? Object(filters).sellToken
          : undefined,
      payToken:
        Object(filters).payToken !== null
          ? Object(filters).payToken
          : undefined,
      termDaysLimit:
        Object(filters).termDaysLimit !== null
          ? Object(filters).termDaysLimit[0]
          : undefined,
      pageIndex: 1,
    });
  };

  const menu = (
    <Menu
      onClick={handleFilterOrder}
      items={[
        {
          label: ORDER_ROLE_ENUM.ALL,
          key: ORDER_ROLE_ENUM.ALL,
        },
        {
          label: ORDER_ROLE_ENUM.EXPIRED,
          key: ORDER_ROLE_ENUM.EXPIRED,
        },
        {
          label: ORDER_ROLE_ENUM.MY_ORDER,
          key: ORDER_ROLE_ENUM.MY_ORDER,
        },
      ]}
    />
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <FilterButton>
          {contentFilter}

          <Space>
            <DownOutlined />
          </Space>
        </FilterButton>
      </Dropdown>
      <TableWrapper>
        <TableStyled
          pagination={false}
          dataSource={data}
          columns={columns}
          onChange={handleTableChange}
          rowKey={(record: { id }) => record.id}
        />
        <Row justify="center" align="middle">
          {totalPage >= 7 && (
            <PaginationStyled
              current={current}
              onChange={onPageChange}
              total={totalPage}
              pageSize={7}
              showSizeChanger={false}
              itemRender={(_, type, originalElement) => {
                if (type === "prev") {
                  return <Image src={prvIcon} alt="" preview={false} />;
                }
                if (type === "next") {
                  return <Image src={nextIcon} alt="" preview={false} />;
                }
                if (type === "jump-prev" || type === "jump-next") {
                  return originalElement;
                } else
                  return (
                    <Image
                      src={
                        listIconsPagi[
                          Math.floor(Math.random() * listIconsPagi.length)
                        ]
                      }
                      alt=""
                      preview={false}
                    />
                  );
              }}
            />
          )}
          {totalPage >= 7 && (
            <SubPagination
              current={current}
              onChange={onPageChange}
              total={totalPage}
              pageSize={7}
              simple
            />
          )}
        </Row>
      </TableWrapper>
      <PaymentModal
        id={id}
        poolAddress={poolAddress}
        payToken={payToken}
        payingSecondTime={payingSecondTime}
        payingSecondTimeFormat={payingSecondTimeFormat}
        visible={visiblePayment}
        success={showSuccess}
        failed={showFailed}
        onCancel={onCloseModal}
        refresh={handleRefreshOrderTable}
      />
      <CancelModal
        id={id}
        visible={visibleCancel}
        success={showSuccess}
        failed={showFailed}
        onCancel={onCloseModal}
        refresh={handleRefreshOrderTable}
      />
      <SuccessModal onCancel={onCloseModal} visible={success} />
      <FailedModal visible={failed} onCancel={onCloseModal} />
    </>
  );
};
