import "antd/dist/antd.min.css";
import store from "redux/store";
import { Provider } from "react-redux";
import { StyledThemeProvider } from "definitions/styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/config.css";
import "./styles/dropdown.css";
import { AppRouter } from "routes";

function App(): JSX.Element {
  return (
    <StyledThemeProvider>
      <Provider store={store}>
        <AppRouter />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="dark"
          bodyStyle={{ fontFamily: "Bicyclette" }}
        />
      </Provider>
    </StyledThemeProvider>
  );
}

export default App;
