import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { LocalhostStorage } from 'utils/sessionStorage';

const POLLING_INTERVAL = 12000;
const CHAIN_ID = +LocalhostStorage.get('chainId');
const TESTNET_CHAIN_ID = 97;
const MAINNET_CHAIN_ID = 56;
const KOVAN_CHAIN_ID = 42;
const AVALANCHE_CHAIN_ID = 43113;
const POLYGON_CHAIN_ID = 80001;
const GSC_CHAIN_ID = 770;
const GOERLI_CHAIN_ID = 5;
const NETWORKS = {
  [TESTNET_CHAIN_ID]: {
    chainIds: [TESTNET_CHAIN_ID],
    rpc: {
      97: process.env.REACT_APP_BSC_NODE,
    },
  },
  [MAINNET_CHAIN_ID]: {
    chainIds: [MAINNET_CHAIN_ID],
    rpc: {
      56: process.env.REACT_APP_NODE_PRODUCTION,
    },
  },
  [AVALANCHE_CHAIN_ID]: {
    chainIds: [AVALANCHE_CHAIN_ID],
    rpc: {
      43113: process.env.REACT_APP_KOVAN_NODE,
    },
  },
  [POLYGON_CHAIN_ID]: {
    chainIds: [POLYGON_CHAIN_ID],
    rpc: {
      80001: 'https://matic-mumbai.chainstacklabs.com',
    },
  },
  [KOVAN_CHAIN_ID]: {
    chainIds: [KOVAN_CHAIN_ID],
    rpc: {
      42: process.env.REACT_APP_KOVAN_NODE,
    },
  },
  [GSC_CHAIN_ID]: {
    chainIds: [GSC_CHAIN_ID],
    rpc: {
      770: process.env.REACT_APP_GSC_NODE,
    },
  },
  [GOERLI_CHAIN_ID]: {
    chainIds: [GOERLI_CHAIN_ID],
    rpc: {
      5: process.env.REACT_APP_CHAIN_ID,
    },
  },
};

export function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
  const library = new Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
}

export const injected = chainId =>
  new InjectedConnector({
    supportedChainIds: NETWORKS[chainId]?.chainIds,
  });

export const getNewWalletConnect = () => {
  return new WalletConnectConnector({
    rpc: NETWORKS[CHAIN_ID].rpc,
    qrcode: true,
  });
};

export const WalletConnectInstance = new WalletConnectConnector({
  rpc: NETWORKS[CHAIN_ID]?.rpc,
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL,
});
