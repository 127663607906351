export enum EventType {
  Claimed = "Claimed",
  Withdrawed = "Withdrawed",
  Deposited = "Deposited",
  Initialized = "Initialized",
  Swap = "Swap",
  CancelledOrder = "CancelledOrder",
  CreatedLayawayPool = "CreatedLayawayPool",
  CreatedOrder = "CreatedOrder",
  ExecutedOrder = "ExecutedOrder",
  OwnershipTransferred = "OwnershipTransferred",
}
