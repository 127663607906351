import OrderButton from "components/button/OrderButton";
import { useEffect, useState } from "react";
import useLaywayRouterCallback from "hooks/layaway-router/useLayawayRouterCallback";
import {
  PaymentContent,
  PaymentModalWrapper,
  TitleStyled,
  Wrapper,
  LineTitle,
  ButtonStyled,
  ButtonBackground,
  WrapperButton,
} from "./styled";
import { ContractType } from "constants/enum/ContractType";
import { EventType } from "constants/enum/EventType";
import { postContractAction } from "services/api/contract_action";
import { formatNumber } from "utils/formatNumber";
import { ETH_ADDRESS } from "constants/value";
import vector from "assets/vectors/underline-payment.svg";
import bgButton from "assets/vectors/bg-payment-button.svg";
import { notification } from "antd";
import { toast } from "react-toastify";

export const PaymentModal = ({
  id,
  poolAddress,
  payingSecondTime,
  payingSecondTimeFormat,
  payToken,
  onCancel,
  visible,
  success,
  failed,
  refresh,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { executeOrder, executeOrderETH } = useLaywayRouterCallback();
  const handleExecuteOrder = async () => {
    try {
      setLoading(true);
      let res = null;
      if (payToken?.tokenAddress === ETH_ADDRESS) {
        res = await executeOrderETH(id, payingSecondTime);
      } else {
        res = await executeOrder(id);
      }

      if (!!res?.transactionHash) {
        const rs = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Router,
          contractAddress: process.env.REACT_APP_LAYAWAY_ROUTER,
          eventName: EventType.ExecutedOrder,
        });

        const ress = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Pool,
          contractAddress: poolAddress,
          eventName: EventType.Swap,
        });

        if (!!rs && !!ress) {
          success();
          refresh();
        } else {
          failed();
        }
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      toast.error(e?.reason || e?.message);
    }
    setLoading(false);
  };

  return (
    <PaymentModalWrapper visible={visible} onCancel={onCancel} footer={false}>
      <Wrapper>
        <TitleStyled>Payment</TitleStyled>
        <LineTitle src={vector} alt="" preview={false} />
      </Wrapper>
      <Wrapper>
        <PaymentContent>Order ID: {id}</PaymentContent>
      </Wrapper>
      <Wrapper>
        <PaymentContent>
          Remaining payment: {formatNumber(payingSecondTimeFormat)}{" "}
          {payToken?.name?.toUpperCase()}
        </PaymentContent>
      </Wrapper>

      <Wrapper>
        <WrapperButton>
          <ButtonBackground src={bgButton} alt="" preview={false} />
          <ButtonStyled
            action="payment"
            onClick={handleExecuteOrder}
            loading={loading}
            disabled={false}
          >
            PAY
          </ButtonStyled>
        </WrapperButton>
      </Wrapper>
    </PaymentModalWrapper>
  );
};
