import { ClaimModal } from "components/modal/ClaimModal";
import { CreateOrderModal } from "components/modal/CreateOrderModal";
import { DepositModal } from "components/modal/DepositModal";
import { SuccessModal } from "components/modal/SuccessModal";
import { WithdrawModal } from "components/modal/WithdrawModal";
import { FailedModal } from "components/modal/FailedModal";
import { RowBetween } from "components/Row";
import Pool from "interfaces/pool";
import { useState, useEffect } from "react";
import { truncateText } from "utils/helper";
import {
  formatThousandWithDecimal,
  formatNumberThousand,
  formatNumber,
} from "utils/formatNumber";
import {
  InfoWrapper,
  ItemWrapper,
  TitleLabel,
  ValueLabel,
  ActionWrapper,
  Action,
  OverlayBackground,
  WrapperTooltip,
  WrapperArea,
  ActionEmpty,
  IconPool,
  IconToken,
} from "./styled";
import usePoolTokenCallback from "hooks/pool-token/usePoolTokenCallback";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { roundNumber } from "utils/roundNumber";
import { DepositETHModal } from "components/modal/DepositModal/depositETHModal";
import { ETH_ADDRESS } from "constants/value";
import iconPool from "assets/icons/icon-user.svg";

import { Image, Row } from "antd";
import { ClaimButton } from "components/TextButton";
import useLaywayRouterCallback from "hooks/layaway-router/useLayawayRouterCallback";
import { iconDefault } from "utils/defaultIcon";
export const InfoRow = ({
  title,
  value,
  url,
  token,
}: {
  title: string;
  value: any;
  url?: string;
  token?: boolean;
}) => {
  return (
    <RowBetween>
      <TitleLabel>{title}</TitleLabel>
      <Row align="middle">
        {token && (
          <IconToken
            src={url || iconDefault}
            fallback={iconDefault}
            preview={false}
          />
        )}
        <ValueLabel>{value}</ValueLabel>
      </Row>
    </RowBetween>
  );
};

export default function PoolItem({
  item,
  refresh,
}: {
  item: Pool;
  refresh: any;
}) {
  const [visibleDeposit, setVisibleDeposit] = useState<boolean>(false);
  const [visibleClaim, setVisibleClaim] = useState<boolean>(false);
  const [visibleWithdraw, setVisibleWithdraw] = useState<boolean>(false);
  const [success, setShowSuccess] = useState(false);
  const [failed, setShowFailed] = useState(false);

  const { account, chainId } = useActiveWeb3React();
  const { claimable, getAvailableWithdraw } = usePoolTokenCallback(
    item?.poolAddress
  );
  const { getCollateral } = useLaywayRouterCallback();
  const [isClaim, setIsClaim] = useState<Number>(undefined);
  const [availableWithdraw, setAvailableWithdraw] = useState<Number>();
  const [feeCollateral, setFeeCollateral] = useState(0);

  const showSuccess = () => {
    setShowSuccess(true);
    setShowFailed(false);
    setVisibleDeposit(false);
    setVisibleClaim(false);
    setVisibleWithdraw(false);
  };

  const showFailed = () => {
    setShowFailed(true);
    setShowSuccess(false);
    setVisibleDeposit(false);
    setVisibleClaim(false);
    setVisibleWithdraw(false);
  };
  const [visiblePurchase, setVisiblePurchase] = useState(false);

  const handleShowDepositModal = () => {
    setVisibleDeposit(!visibleDeposit);
  };

  const handleShowClaimModal = () => {
    setVisibleClaim(!visibleClaim);
  };
  const handleShowWithdrawModal = () => {
    setVisibleWithdraw(!visibleWithdraw);
  };

  const onCloseModal = () => {
    setVisibleDeposit(false);
    setVisibleClaim(false);
    setVisibleWithdraw(false);
    setShowSuccess(false);
    setShowFailed(false);
  };

  useEffect(() => {
    void (async () => {
      const resClaim = await claimable();
      setIsClaim(Number(resClaim));

      const res = await getAvailableWithdraw();
      setAvailableWithdraw(Number(res));
    })();
  }, [account, chainId, item]);

  useEffect(() => {
    void (async () => {
      const res = await getCollateral();
      setFeeCollateral(res / 100);
    })();
  }, [account, chainId]);

  return (
    <ItemWrapper>
      <InfoWrapper>
        <IconPool>
          <Image src={iconPool} alt="" preview={false} />
        </IconPool>
        <WrapperTooltip title={item?.poolAddress} color="#000">
          <ValueLabel>{truncateText(item?.poolAddress, 4, 4)}</ValueLabel>
        </WrapperTooltip>
        <InfoRow
          title="Sell token"
          token
          value={item?.sellToken?.symbol}
          url={item?.sellToken?.img}
        ></InfoRow>
        <InfoRow
          title="Paying token"
          token
          value={item?.payToken?.symbol}
          url={item?.payToken?.img}
        ></InfoRow>
        <InfoRow
          title="Sell amount"
          value={formatNumberThousand(
            roundNumber(item?.availableSellAmountFormat, 5)
          )}
        ></InfoRow>
        <InfoRow
          title="Sold amount"
          value={formatNumberThousand(roundNumber(item?.soldAmountFormat, 5))}
        ></InfoRow>
        <InfoRow
          title="Price"
          value={formatNumber(item?.priceFormat)}
        ></InfoRow>
        <InfoRow title="Collateral rate" value={`${feeCollateral}%`}></InfoRow>
      </InfoWrapper>
      <ActionWrapper>
        {isClaim ? (
          <ClaimButton onClick={handleShowClaimModal}>
            {`Claim ${(item?.payToken.symbol).toLocaleUpperCase()}`}
          </ClaimButton>
        ) : (
          <ActionEmpty></ActionEmpty>
        )}
        {item?.isPoolOpen ? (
          <RowBetween>
            <Action onClick={handleShowDepositModal}>Deposit</Action>
            {!availableWithdraw ? (
              <Action onClick={() => setVisiblePurchase(true)}>Purchase</Action>
            ) : (
              <Action
                color="#CFF54D"
                colorText="#000"
                onClick={handleShowWithdrawModal}
              >
                Withdraw
              </Action>
            )}
          </RowBetween>
        ) : (
          <WrapperArea></WrapperArea>
        )}
      </ActionWrapper>
      <OverlayBackground>
        {item?.sellToken?.tokenAddress !== ETH_ADDRESS ? (
          <DepositModal
            poolAddress={item?.poolAddress}
            sellToken={item?.sellToken}
            visible={visibleDeposit}
            success={showSuccess}
            failed={showFailed}
            onCancel={onCloseModal}
            refresh={refresh}
          />
        ) : (
          <DepositETHModal
            poolAddress={item?.poolAddress}
            sellToken={item?.sellToken}
            visible={visibleDeposit}
            success={showSuccess}
            failed={showFailed}
            onCancel={onCloseModal}
            refresh={refresh}
          />
        )}
      </OverlayBackground>
      <OverlayBackground>
        <ClaimModal
          poolAddress={item?.poolAddress}
          visible={visibleClaim}
          success={showSuccess}
          failed={showFailed}
          refresh={refresh}
          onCancel={() => setVisibleClaim(false)}
          payToken={item?.payToken}
        />
      </OverlayBackground>
      <OverlayBackground>
        <WithdrawModal
          poolAddress={item?.poolAddress}
          payToken={item?.payToken}
          sellToken={item?.sellToken}
          visible={visibleWithdraw}
          success={showSuccess}
          failed={showFailed}
          onCancel={() => setVisibleWithdraw(false)}
          refresh={refresh}
        />
      </OverlayBackground>
      <SuccessModal visible={success} onCancel={onCloseModal} />
      <FailedModal visible={failed} onCancel={onCloseModal} />
      <CreateOrderModal
        poolAdress={item?.poolAddress}
        sellToken={item?.sellToken}
        payToken={item?.payToken}
        price={item?.priceFormat}
        availableSellAmount={item?.availableSellAmountFormat}
        open={visiblePurchase}
        onCancel={() => setVisiblePurchase(false)}
        refresh={refresh}
      />
    </ItemWrapper>
  );
}
