import styled from 'styled-components';
import { Menu } from 'antd';
import { useDisconnect } from 'hooks/useDisconnect';
import { ItemMenu } from 'components/usersMenu/styled';
import {LineDisconnect} from './styled'
import vector from "assets/vectors/vector-disconnect.svg"
const { Item } = Menu;

const DisconnectText = styled.span`
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
`;

const DisconnectItem = () => {
  const disconnect = useDisconnect();

  return (
    <ItemMenu onClick={disconnect} key='disconnect'>
      <DisconnectText>Disconnect</DisconnectText>
      <LineDisconnect src={vector} alt="" preview={false}/>
    </ItemMenu>
  );
};

export default DisconnectItem;
