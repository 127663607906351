import { useState } from "react";
import { postTokenWithAddress, getToken, IToken } from "services/api";
import { Form, notification, Row } from "antd";
import { NotificationEnum } from "constants/enum/Notifications";
import { StyledForm } from "components/StyledForm";
import Web3 from "web3";
import {
  AddNewTokenModalWrapper,
  TitleStyled,
  Wrapper,
  WrapperContent,
  InputField,
  AddNewTokenButton,
  WrapperAddButton,
  FormItemInput,
  IconToken,
} from "./styled";
import { ButtonBackground } from "../CreatePoolModal/styled";
import bgButton from "assets/vectors/vector-deposit-button.svg";
import { useToggleModal } from "redux/slices/application/hook";
import { ApplicationModal } from "redux/slices/application";
import { refreshNewToken } from "redux/slices/refreshToken";
import { useDispatch } from "react-redux";

export const AddNewTokenModal = ({ onCancel, visible }) => {
  const toggleCreatePool = useToggleModal(ApplicationModal.CREATE_POOL);
  const [inputText, setInputText] = useState(undefined);
  const [inputUrl, setInputUrl] = useState(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const web3 = new Web3(Web3.givenProvider);
  const [params, setParams] = useState<IToken>({
    pageIndex: 1,
    pageSize: 6,
    search: undefined,
    tokenAddress: undefined,
  });

  const handleAddToken = async () => {
    const check = await getToken({ ...params, tokenAddress: inputText});
    if(check?.payload?.totalCount > 0) {
      notification.open({
        message: NotificationEnum.EXIST_TOKEN,
    });
      return;
    }
    setLoading(true);
    const res = await postTokenWithAddress({
      tokenAddress: inputText,
      img: inputUrl,
    });
    setLoading(false);
    if (res.success) {
      notification.open({
        message: NotificationEnum.CREATE_TOKEN_SUCCESS,
      });
      form.resetFields();
      setInputUrl(undefined);
      dispatch(refreshNewToken());
      toggleCreatePool();
      setInputText("");
      onCancel();
    } else {
      notification.open({
        message: NotificationEnum.CREATE_TOKEN_FAILED,
      });
    }
  };

  const checkValidContractAddress = (address: string) => {
    return web3.utils.isAddress(address);
  };

  return (
    <AddNewTokenModalWrapper
      visible={visible}
      onCancel={onCancel}
      footer={false}
    >
      <Wrapper>
        <TitleStyled>Create new token</TitleStyled>
      </Wrapper>

      <WrapperContent>
        <StyledForm
          autoComplete="off"
          layout="vertical"
          labelcolor="#fff"
          form={form}
        >
          <FormItemInput name={["addressToken"]} label="Address Token">
            <InputField
              value={inputText || null}
              min={0}
              onChange={(e) => setInputText(e.target.value)}
              disabled={loading}
            ></InputField>
          </FormItemInput>
          <FormItemInput
            className="mt-4"
            name={["urlToken"]}
            label={
              <>
                Url icon token
                <IconToken src={inputUrl} alt="" preview={false} />
              </>
            }
          >
            <InputField
              // value={inputUrl || null}
              min={0}
              onPressEnter={(e) => setInputUrl(e.currentTarget.value)}
              onBlur={(e) => setInputUrl(e.target.value)}
              disabled={loading}
            ></InputField>
          </FormItemInput>
        </StyledForm>
      </WrapperContent>
      <WrapperAddButton>
        <ButtonBackground src={bgButton} alt="" preview={false} />
        <Row justify="center">
          <AddNewTokenButton
            loading={loading}
            disabled={!checkValidContractAddress(inputText)}
            onClick={handleAddToken}
          >
            Create
          </AddNewTokenButton>
        </Row>
      </WrapperAddButton>
    </AddNewTokenModalWrapper>
  );
};
