import Header from "components/header";
import { Desktop } from "styles/Common/HeaderDesktop.styled";
import { Footer } from "components/footer";
import HomeSegment from "components/HomeSegment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import {
  WrapperHome,
  MainContent,
  MainBackground,
} from "./styled";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setPage } from "redux/slices/page";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { LocalhostStorage } from "utils/sessionStorage";
import { PoolLayaway } from "constants/routers";
import background from "assets/images/homepage.png";
import { Image } from "antd";
import bg from "assets/images/background.png";
import { Spin } from 'antd';

export default function HomepageTemplate({ children }) {
  const isConnected = Boolean(LocalhostStorage.get("isConnected"));
  const dispatch = useDispatch();
  const { page } = useSelector((state: RootState) => state.page);
  const location = useLocation().pathname;

  useEffect(() => {
    if (location === PoolLayaway.POOL) dispatch(setPage("Pool"));
    if (location === PoolLayaway.ORDER) dispatch(setPage("Order"));
  }, [page]);
  const { account } = useActiveWeb3React();
  let signature = LocalhostStorage.get("signature");


  return (
    <>
      <Desktop>
        { (account && signature ) && (
          <>
            <Header />
            <MainBackground src={bg} alt="" preview={false} />
            <WrapperHome>
              <HomeSegment />
            </WrapperHome>
         </>
        )}
        <MainContent>{children}</MainContent>
      </Desktop>
    </>
  );
}
