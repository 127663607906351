import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Space, Tabs } from "antd";
import AppColumn from "components/Column";
import styled, { keyframes } from "styled-components";

export const Mobile = styled.div`
  display: none;
  @media (max-width: 1400px) {
    display: block;
  }
`;

export const Desktop = styled(AppColumn)`
  height: 100vh;
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  /* min-height: 220px; */
  // border-bottom: 1px solid #413986;
  margin-bottom: 30px;
`;

export const WrapperLogo = styled.div`
  /* margin-top: 20px;
	margin-left: 50px; */
  @media (max-width: 1600px) {
    margin-left: 0;
  }
`;

export const Logo = styled.div`
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
`;

export const WrapperNavbar = styled.div`
  /* display: flex;
  flex-direction: column; */
  /* width: 100%; */
  text-align: right;
`;

export const SpaceStyled = styled(Space)`
  p:hover {
    // background: linear-gradient(90deg, #06d3e6 -0.15%, #ffdb0d 96.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -text-fill-color: transparent;
  }
`;

export const ListNavBottom = styled.ul`
  list-style: none;
  margin: 0;
  margin-right: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-bottom: 20px; */
  @media (max-width: 1600px) {
    margin-right: 0;
  }

  @media (max-width: 1400px) {
    width: fit-content;
  }
`;

export const ListNavTop = styled.ul`
  list-style: none;
  margin: 0;
  margin-right: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1600px) {
    margin-right: 0px;
  }
  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const IconNavTop = styled.div`
  margin-right: 8px;
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TextNavTop = styled.li`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

export const ItemLink = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    color: rgba(255, 255, 255, 0.6) !important;
  }
`;

export const TextNavBottom = styled.p``;

export const ItemLinkBottom = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #777e90;
  margin-bottom: 20px;
  @media (max-width: 1450px) {
    font-size: 14.5px;
  }

  @media (max-width: 1500px) {
    font-size: 14.5px;
  }
`;

interface ISubTab {
  isActive?: boolean;
}

const checkColor = (a) => {
  if (a === true) {
    return `
    background: linear-gradient(90deg, #06D3E6 -0.15%, #FFDB0D 96.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    `;
  } else {
    return "color: #fff";
  }
};

export const SubTextNavBottom = styled.p<ISubTab>`
  margin-left: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 14px;
  text-transform: capitalize;
  cursor: pointer;
  ${(p) => (p.isActive ? checkColor(true) : checkColor(false))}
`;

export const WrapperSubTab = styled.div`
  position: relative;
`;

export const ConnectWalletBtn = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 36px;
  background: linear-gradient(90.84deg, #fe2900 -3.26%, #ffa500 105.1%);
  border-radius: 5px;
  cursor: pointer;
`;

export const TextConnectBtn = styled.p`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #ffffff;
`;

export const WalletIcon = styled.div`
  margin-right: 8px;
  svg {
    width: 17px;
    height: 13.4px;
  }
`;

export const WrapperAddress = styled.div`
  margin-left: 24px;
  padding: 10px 35px;
  background: linear-gradient(
    0deg,
    rgba(197, 71, 34, 0.3) 5.56%,
    rgba(216, 79, 20, 0) 36.11%
  );
  border: 2px solid orange;
  border-radius: 30px;

  p {
    margin: 0;
    font-family: "Inter";
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-transform: capitalize;
    color: #ffffff;
    mix-blend-mode: normal;
  }
`;

export const TabsNavbar = styled(Tabs)`
  width: auto !important;
  max-width: 1120px !important;
  position: relative;
  @media (max-width: 1600px) {
    margin-right: 0px;
  }
  @media (max-width: 1450px) {
    max-width: 1150px !important;
  }

  overflow: unset;

  .ant-tabs-content-holder {
    position: absolute;
    bottom: -40px;
    max-width: 1000px;
    white-space: nowrap;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    opacity: 1;
    * {
      color: #fff;
    }
  }

  .ant-tabs-tab {
    opacity: 1;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: unset;
  }
  .ant-tabs-nav-wrap .ant-tabs-nav-list {
    justify-content: flex-end !important;
  }
  .ant-tabs-nav {
    width: 100%;
    overflow-x: auto;
    margin: 0;
  }
  .ant-tabs-tabpane {
    text-align: left;
    margin-left: 0;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-nav-operations {
    .ant-tabs-nav-more {
      display: none;
    }
    display: none;
  }
  .ant-tabs-nav::before {
    border: none !important;
    max-width: 1150px !important;
    /* border: 1px solid #413986 !important; */
  }
  .ant-tabs-ink-bar {
    border: 1px solid #814bf6;
    box-shadow: 0px 0px 3px #df1cff;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    justify-content: flex-end !important;
  }
`;
const { TabPane } = Tabs;

export const TabsItem = styled(TabPane)<{ index: number }>`
  height: 30px;
  margin-left: ${(p) =>
    p.index === 1
      ? 40 + "px"
      : p.index === 4
      ? 370 + "px "
      : p.index === 6
      ? 175 + "px "
      : p.index === 7
      ? -45 + "px "
      : p.index === 11
      ? -120 + "px "
      : p.index === 12
      ? 165 + "px "
      : "0 "} !important;

  @media (max-width: 1500px) {
    margin-left: ${(p) =>
      p.index === 1
        ? 40 + "px"
        : p.index === 4
        ? 375 + "px "
        : p.index === 6
        ? 186 + "px "
        : p.index === 7
        ? -80 + "px "
        : p.index === 11
        ? -80 + "px "
        : p.index === 12
        ? 170 + "px "
        : "0 "} !important;
  }

  .ant-tabs-nav {
    min-width: 280px;
    height: unset !important;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 35px 20px;
    margin-right: 20px;
    @media (max-width: 768px) {
      height: unset !important;
      background: unset !important;
      backdrop-filter: unset !important;
      border-radius: unset !important;
      padding: unset !important;
      margin-right: unset !important;
    }
  }

  .ant-tabs-nav-list {
    flex: unset !important;
  }

  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    border: none;
    height: 672px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 35px 20px;
  }
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    justify-content: flex-start !important;
  }
`;

export const LinkTag = styled.a`
  width: 100%;
  position: relative;
  font-family: "Inter";
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #938ca7;
  &:hover {
  }
`;

export const IconDropdown = styled(DownOutlined)`
  svg {
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
`;
export const IconDropup = styled(UpOutlined)`
  svg {
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
`;

export const WrapperFixed = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: ${(p) => (p.show ? "100%" : "90px")};
  background: ${(p) => p.theme.colors.background};
  z-index: 999;
`;

export const MobileWrap = styled.div<{ show: Boolean }>`
  position: ${(p) => (p.show ? "fixed" : "none")};
  right: 0;
  z-index: 1100;
  height: 90px;
  display: flex;
  margin-left: 1em;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(p) =>
    !p.show ? "1px solid rgba(129, 75, 246, 0.4)" : "none"};
  margin: 0 24px;
  button {
    width: 3em;
    height: 3em;
  }
`;
const showMenu = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
     transform: translateY(0);
  }

`;

export const BackgroundMobile = styled.div`
  position: relative;
  padding: 1em;
  text-align: center;
  width: 100%;
  height: 100vh !important;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.4s;
  background: rgb(19, 20, 24) !important;
  z-index: 1000;
  animation: ${showMenu} 1s ease;
  padding-bottom: 150px;
  padding-top: 50px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Transparent = styled(Button)`
  &[ant-click-animating-without-extra-node="true"]::after,
  .ant-click-animating-node {
    display: none;
  }
  border-color: transparent !important;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: Inter;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
`;

export const Title = styled.span`
  width: 100%;
`;

export const ListIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -20px;
`;

export const ItemIcon = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-left: 40px;
  height: 30px;
  color: #fff;
  font-size: 16px;
  line-height: 15px;
  margin-bottom: 10px;
`;

export const ItemTitle = styled.span`
  height: 30px;
  line-height: 15px;
  margin-left: 10px;
  font-weight: 600;
  padding-top: 8px;
  text-transform: uppercase;
`;
