import { Input } from "antd";
import styled from "styled-components/macro";

export const TextInputField = styled(Input)<{
  placeHolderColor?;
}>`
  width: 100%;
  padding: 10px;
  border-radius: 37px;
  border: 1px solid #9600b0;
  background-color: transparent;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  font-family: "Bicyclette Bold";
  color: #fff;
  ::placeholder {
    color: ${({ placeHolderColor }) => placeHolderColor || "#9600B0"};
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }

  .nt-input-affix-wrapper {
    background-color: transparent !important;
  }
  :focus, :hover {
    border: 1px solid #9600b0;
    background-color: transparent;
  }
  .ant-input {
    background-color: transparent;
    color: white;
  }

  .ant-input-suffix {
    color: white;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;
