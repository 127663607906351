import { PoolLayaway } from "constants/routers";
import Login from "pages/Login2Homepage";
import { OrderPage } from "pages/Order";
import { PoolPage } from "pages/Pool";
import { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { Redirect } from "./Redirect";

export const AppRouter = () => {
  return (
    <Router basename="/">
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path={PoolLayaway.HOME} element={<Login />} />
          <Route path="*" element={<Redirect to="/" />} />
          <Route
            path={PoolLayaway.ORDER}
            element={
              <PrivateRoute>
                <OrderPage />
              </PrivateRoute>
            }
          />
          <Route
            path={PoolLayaway.POOL}
            element={
              <PrivateRoute>
                <PoolPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};
