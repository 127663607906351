import { Select } from "antd";
import styled from "styled-components";

export const SelectStyled = styled(Select)`
  color: #fff;
  background: #000 !important;
  border: #cff54d 1px solid !important;
  font-size: 16px;
  font-weight: 400 !important;
  border-radius: 24px;
  &.ant-select {
    border-radius: 24px;
    width: 100%;
    background: #000 !important;
    height: 36px;
    border: #cff54d 1px solid !important;
    box-shadow: none !important;
    :disabled{
      color: #ccc;
    }
  }
  .ant-select-selector {
    background: #000 !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 24px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-position: #000;
    border: none !important;
  }
  .ant-select-selection-item {
    background: #000 !important;
    border: none !important;
    font-weight: 400 !important;
    border-radius: 10px;
    padding-top: 10px;
    line-height: 33px !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
    line-height: 32px;
  }
  @media(min-width: 1400px){
    font-size: 18px;
  }
  @media(min-width: 1600px){
    font-size: 22px;
  }
`;

export const SelectStyled2 = styled(Select)`
  border-radius: 37px;
  border: 1px solid #9600b0;
  color: #fff;
  width: 100%;
  font-size: 15px;
  font-family: "BicycletteRe";
  .ant-select-selector {
    background: transparent !important;
    width: 90% !important;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-search-input {
    color: #fff;
  }
  .ant-select-arrow {
    color: #9600b0;
  }
  

  .ant-select-clear {
    background: transparent;
    span {
      position: absolute;
      right: 0px;
    }
    svg{
      fill: #ccc;
    }
  }
  .ant-select-item-option{
    padding: 8px 14px;
  }
  .ant-select-clear{
    background: transparent;
    right: 32px;
  }
`;
