import { OrderButtonStyled } from "./styled";

const OrderButton = ({ children, action, onClick, loading, disabled }) => {
  return (
    <>
      <OrderButtonStyled
        action={action}
        onClick={onClick}
        loading={loading}
        disabled={disabled}
      >
        {children}
      </OrderButtonStyled>
    </>
  );
};
export default OrderButton;
