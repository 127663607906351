import { Button, Form, Input, Modal, Row, Image } from "antd";
import styled from "styled-components";

export const AddNewTokenModalWrapper = styled(Modal)`
  top: calc(calc(100% - 400px) / 2);
  .ant-modal-content {
    background: #000;
    border: #CFF54D 1px solid;
    border-radius: 10px;    
    .ant-modal-body {
    }
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 56px;
`;

export const TitleStyled = styled.p`
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
`;

export const WrapperContent = styled.div``;

export const FormItemInput = styled(Form.Item)`
  margin-bottom: 0px;
  padding: 0px;
  .ant-form-item-label > label{
    margin-left: 14px;
  }
`;

export const InputField = styled(Input)`
  padding-top: 8px;
  padding-bottom: 8px;
  background: transparent;
  border: 1px solid #CFF54D;
  border-radius: 24px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;

  :hover,
  :focus {
    border: 1px solid #CFF54D;
  }
  :disabled,
  :disabled:hover {
    background-color: transparent !important;
    color: #fff !important;
  }
  .ant-input-affix-wrapper > input.ant-input {
    background: transparent !important;
  }
  .ant-input-clear-icon,
  .anticon.ant-input-clear-icon {
    color: #fff;
    display: flex;
    justify-content: center;
  }
  
`;

export const WrapperAddButton = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  .ant-image{
    height: 0;
  }
`;

export const AddNewTokenButton = styled(Button)<any>`
  border-radius: 10px;
  /* width: 100%; */
  border: none;
  font-weight: 700;
  height: 40px;
  display: flex;
  margin-top: -12px;
  background: transparent !important;
  color: #cff54d !important;
  justify-content: center;
  align-items: center;
  :disabled,
  :disabled:hover {
    background-color: transparent;
    opacity: 0.6;
    background-image: none !important;
  }
  :hover, :focus {
    background: transparent;
    color: #cff54d;
  }
`;

export const IconToken = styled(Image)`
  width: 30px;
  margin-left: 16px;
`;