import { ethers } from "ethers";
import useSellTokenCallback from "hooks/sell-token/useSellTokenCallback";
import usePoolTokenCallback from "hooks/pool-token/usePoolTokenCallback";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useEffect, useState } from "react";
import { blockInvalidChar, formatNumber, formatNumberThousand } from "utils/formatNumber";
import {
  DepositModalWrapper,
  Wrapper,
  TitleStyled,
  LabelWrapper,
  InputWrapper,
  InputField,
  WrapperContent,
  LabelValueWrapper,
  WrapperRow,
  RightInput,
  WrapperButton,
  DepositButton,
  ButtonWrapper,
  LineTitle,
  ButtonRow,
} from "./styled";
import { postContractAction } from "services/api/contract_action";
import { notification } from "antd";
import { ContractType } from "constants/enum/ContractType";
import { EventType } from "constants/enum/EventType";
import { NotificationEnum } from "constants/enum/Notifications";
import { roundDown, roundNumber } from "utils/roundNumber";
import { ButtonBackground } from "../CreateOrderModal/styled";
import vector from "assets/vectors/vector-disconnect.svg";
import bgButton from "assets/vectors/vector-deposit-button.svg";
import { toast } from "react-toastify";
const ETH_ADDRESS = "0x0000000000000000000000000000000000000000";

export const DepositModal = ({
  poolAddress,
  sellToken,
  visible,
  success,
  failed,
  onCancel,
  refresh,
}) => {
  const { account, chainId } = useActiveWeb3React();
  const { allowance, balanceOf, approve } = useSellTokenCallback(
    sellToken?.tokenAddress
  );

  const { deposit } = usePoolTokenCallback(poolAddress);
  const [allowanceToken, setAllowanceToken] = useState<Number>(undefined);
  const [availableNumber, setAvailableNumber] = useState(undefined);
  const [inputText, setInputText] = useState(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshAllowance, setRefreshAllowance] = useState<boolean>(false);
  const CONVERT_2_NUMBER = 10 ** parseFloat(sellToken?.decimal);

  const handleButtonMax = () => {
    setInputText(ethers.utils.formatUnits(availableNumber || 0, sellToken?.decimal || 1));
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      const res = await approve(poolAddress);

      if (res) {
        setRefreshAllowance(true);
        notification.open({
          message: NotificationEnum.APPROVE_SUCCESS,
        });
      } else {
        setRefreshAllowance(false);
        notification.open({
          message: NotificationEnum.APPROVE_ERROR,
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      setRefreshAllowance(false);
      notification.open({
        message: NotificationEnum.APPROVE_ERROR,
      });
    }
  };

  const handleDeposit = async () => {
    try {
      setLoading(true);
      const res = await deposit(
        ethers.utils.parseUnits(inputText, parseFloat(sellToken?.decimal))
      );

      if (!!res?.transactionHash) {
        const rs = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Pool,
          contractAddress: poolAddress,
          eventName: EventType.Deposited,
        });
        if (!!rs) {
          success();
          refresh();
        } else {
          failed();
        }
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      toast.error(e?.reason || e?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    void (async () => {
      const resAllowance = await allowance(poolAddress);
      if (resAllowance) setAllowanceToken(Number(resAllowance));
      const resBalanceOf = await balanceOf(account);
      if (resBalanceOf) setAvailableNumber(resBalanceOf);
    })();
    return () => {
      setInputText(null);
    };
  }, [account, chainId, refreshAllowance, poolAddress, visible]);

  const handleInputChange = (value) => {
    let temp = value;
    value =
      temp.indexOf(".") >= 0
        ? temp.substr(0, temp.indexOf(".")) + temp.substr(temp.indexOf("."), sellToken?.decimal || 6)
        : temp;
    setInputText(value);
  };

  return (
    <DepositModalWrapper
      visible={visible}
      onCancel={onCancel}
      footer={false}
      closable={false}
    >
      <Wrapper>
        <TitleStyled>Deposit</TitleStyled>
        <LineTitle src={vector} alt="" preview={false} />
      </Wrapper>
      <WrapperContent>
        <LabelWrapper className="mb-1">Deposit amount</LabelWrapper>
        <InputWrapper>
          <InputField
            value={inputText || null}
            type={"number"}
            onKeyDown={blockInvalidChar}
            bordered={false}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
            disabled={loading}
          ></InputField>
          <RightInput>
            <LabelWrapper className="mb-0">
              {sellToken?.symbol?.toUpperCase()}
            </LabelWrapper>
            <WrapperButton onClick={handleButtonMax} disabled={loading}>
              Max
            </WrapperButton>
          </RightInput>
        </InputWrapper>

        <WrapperRow justify="end">
          <LabelValueWrapper color="#8C8C8C">Available: </LabelValueWrapper>
          <LabelValueWrapper className="ms-3">
            {formatNumberThousand(Number(ethers.utils.formatUnits(availableNumber || 0, sellToken?.decimal || 1)))} {" "}
            {sellToken?.symbol?.toUpperCase()}
          </LabelValueWrapper>
        </WrapperRow>
      </WrapperContent>
      <ButtonWrapper>
        <ButtonBackground src={bgButton} alt="" preview={false} />
        <ButtonRow justify="center">
          {allowanceToken || sellToken?.tokenAddress === ETH_ADDRESS ? (
            <DepositButton
              loading={loading}
              onClick={handleDeposit}
              disabled={
                !inputText
                ||
                Number(inputText) <= 0 ||
                Number(inputText) > Number(ethers.utils.formatUnits(availableNumber || 0, sellToken?.decimal || 1))
              }
            >
              Deposit
            </DepositButton>
          ) : (
            <DepositButton loading={loading} onClick={handleApprove}>
              Approve
            </DepositButton>
          )}
        </ButtonRow>
      </ButtonWrapper>
    </DepositModalWrapper>
  );
};
