import { ethers } from "ethers";
import usePoolTokenCallback from "hooks/pool-token/usePoolTokenCallback";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useEffect, useState } from "react";
import {
  DepositModalWrapper,
  Wrapper,
  TitleStyled,
  LabelWrapper,
  InputWrapper,
  InputField,
  WrapperContent,
  LabelValueWrapper,
  WrapperRow,
  RightInput,
  WrapperButton,
  DepositButton,
  LineTitle,
  ButtonWrapper,
  ButtonRow,
} from "./styled";
import { postContractAction } from "services/api/contract_action";
import { ContractType } from "constants/enum/ContractType";
import { EventType } from "constants/enum/EventType";
import vector from "assets/vectors/vector-disconnect.svg";
import bgButton from "assets/vectors/vector-deposit-button.svg";
import { ButtonBackground } from "../CreateOrderModal/styled";
import { toast } from "react-toastify";
import { blockInvalidChar, formatNumberThousand } from "utils/formatNumber";
const ROUND_NUMBER = 10000;
const ROUND_AFTER_COMMA = 4;

export const DepositETHModal = ({
  poolAddress,
  sellToken,
  visible,
  success,
  failed,
  onCancel,
  refresh,
}) => {
  const { account, chainId } = useActiveWeb3React();
  const { depositETH } = usePoolTokenCallback(poolAddress);
  const [availableNumber, setAvailableNumber] = useState(undefined);
  const [inputText, setInputText] = useState(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    void (async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const balance = await provider.getBalance(account);
      const balanceInEth = ethers.utils.formatEther(balance);
      setAvailableNumber(balanceInEth);
    })();
  }, [account, chainId]);

  const handleButtonMax = () => {
    setInputText(availableNumber || 0);
  };

  const handleDeposit = async () => {
    try {
      setLoading(true);
      const res = await depositETH(
        ethers.utils.parseUnits(inputText, parseFloat(sellToken?.decimal))
      );

      if (!!res?.transactionHash) {
        const rs = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Pool,
          contractAddress: poolAddress,
          eventName: EventType.Deposited,
        });
        if (!!rs) {
          success();
          refresh();
        } else {
          failed();
        }
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      toast.error(e?.reason || e?.message);
    }
    setLoading(false);
  };

  const handleInputChange = (value) => {
    let temp = value;
    value =
      temp.indexOf(".") >= 0
        ? temp.substr(0, temp.indexOf(".")) + temp.substr(temp.indexOf("."), sellToken?.decimal || 6)
        : temp;
    setInputText(value);
  };

  return (
    <DepositModalWrapper
      visible={visible}
      onCancel={onCancel}
      footer={false}
      closable={false}
    >
      <Wrapper>
        <TitleStyled>Deposit</TitleStyled>
        <LineTitle src={vector} alt="" preview={false} />
      </Wrapper>
      <WrapperContent>
        <LabelWrapper>Deposit amount</LabelWrapper>
        <InputWrapper>
          <InputField
            value={inputText || null}
            type={"number"}
            onKeyDown={blockInvalidChar}
            bordered={false}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
            disabled={loading}
          ></InputField>
          <RightInput>
            <LabelWrapper className="mb-0">
              {sellToken?.symbol?.toUpperCase()}
            </LabelWrapper>
            <WrapperButton onClick={handleButtonMax} disabled={loading}>
              Max
            </WrapperButton>
          </RightInput>
        </InputWrapper>

        <WrapperRow justify="end">
          <LabelValueWrapper color="#8C8C8C">Available: </LabelValueWrapper>
          <LabelValueWrapper>
            {formatNumberThousand(Number(availableNumber || 0))} {" "}
            {sellToken?.symbol?.toUpperCase()}
          </LabelValueWrapper>
        </WrapperRow>
      </WrapperContent>
      <ButtonWrapper>
        <ButtonBackground src={bgButton} alt="" preview={false} />
        <ButtonRow justify="center">
          <DepositButton
            loading={loading}
            onClick={handleDeposit}
            disabled={
              !inputText ||
              Number(inputText) <= 0 ||
              Number(inputText).toFixed(ROUND_AFTER_COMMA) >
              Number(availableNumber).toFixed(ROUND_AFTER_COMMA)
            }
          >
            Deposit
          </DepositButton>
        </ButtonRow>
      </ButtonWrapper>
    </DepositModalWrapper>
  );
};
