import { Button, Input, Modal, Row, Image } from "antd";
import { AppRow, RowCenter } from "components/Row";
import { TextButton } from "components/TextButton";
import styled from "styled-components";

export const DepositModalWrapper = styled(Modal)`
  top: calc(calc(100% - 400px) / 2);
  @media(min-width: 1400px){
    min-width: 650px;
  }

  .ant-modal-content {
    background: #000;
    border-radius: 10px;
    border: #cff54d 1px solid;
    .ant-modal-body {
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;
export const LabelWrapper = styled.label`
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  font-family: 'BicycletteRe';
  margin-bottom: 6px;
  margin-left: 10px;
  @media(min-width: 1400px){
    font-size: 16px;
  }
  @media(min-width: 1600px){
    font-size: 20px;
  }
`;
export const LabelValueWrapper = styled.label`
  font-size: 12px;
  font-weight: 400;
  margin-right: 4px;
  color: ${(props) => props.color || "#fff"};
  @media(min-width: 1600px){
    font-size: 18px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 56px;
`;

export const WrapperContent = styled.div``;

export const WrapperRow = styled(Row)`
  margin-top: 4px;
  margin-bottom: 20px;
`;

export const TitleStyled = styled.p`
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  font-family: 'BicycletteRe';

  @media(min-width: 1400px){
    font-size: 22px;
  }
  @media(min-width: 1600px){
    font-size: 26px;
  }
`;

export const InputWrapper = styled(AppRow)`
  width: 100%;
  border-radius: 24px;
  border: 1px solid #cff54d;
  background-color: transparent;
  line-height: 18px;
  justify-content: space-between;
`;

export const RightInput = styled(RowCenter)`
  width: auto;
`;

export const InputField = styled(Input)`
  width: 65%;
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  :disabled,
  :disabled:hover {
    background-color: transparent !important;
    color: #fff !important;
  }
  @media(min-width: 1400px){
    font-size: 18px;
  }
  @media(min-width: 1600px){
    font-size: 22px;
  }
`;

export const WrapperButton = styled(TextButton)`
  margin: 5px;
  /* width: 50px; */
  height: 24px;
  padding: 0px 15px;
  background: #cff54d;
  border-radius: 80px;
  color: #000;
  border: none;
  font-weight: 700;
  :hover{
    background: #cff54d;
    color: #000;
  }
  :disabled,
  :disabled:hover {
    color: grey;
  }
  width: 70px;
  text-align: center;
  @media(min-width: 1400px){
    font-size: 16px;
  }
  @media(min-width: 1600px){
    font-size: 20px;
    height: 32px;
  }
`;

export const DepositButton = styled(Button)<any>`
  border-radius: 10px;
  width: fix-content;
  font-size: 16px;
  border: none;
  font-weight: 700;
  height: 40px;
  display: flex;
  margin-top: -23px;
  background: transparent !important;
  color: #cff54d !important;
  justify-content: center;
  align-items: center;
  :disabled,
  :disabled:hover {
    background-color: transparent;
    opacity: 0.6;
  }
  :hover,
  :focus {
    background: transparent;
    color: #cff54d;
  }
  @media(min-width: 1400px){
    font-size: 16px;
    letter-spacing: 0.1rem;
  }
  @media(min-width: 1600px){
    font-size: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  .ant-image {
    height: 0;
  }
  .ant-btn:before{
    background-color: transparent;
  }
`;

export const LineTitle = styled(Image)`
  width: 74px;
  display: block;
  position: absolute;
  bottom: 4px;
  right: -6px;
  height: 20px;
  @media(min-width: 1400px){
    width: 85px;
    bottom: 12px;
  }
  @media(min-width: 1600px){
    width: 95px;
    bottom: 12px;
  }
`;

export const ButtonRow = styled(Row)``;