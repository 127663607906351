import { OrderTable } from "components/Table/OrderTable";
import HomepageTemplate from "template/Homepage";
import { TableWrapper } from "./styled";
import { RootState } from "redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { PoolLayaway } from "constants/routers";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { LocalhostStorage } from "utils/sessionStorage";

export const OrderPage = () => {
  const navigate = useNavigate();
  const { account, chainId } = useActiveWeb3React();
  const { isSignar } = useSelector((state: RootState) => state.signar);
  const isConnected = Boolean(LocalhostStorage.get("isConnected"));
  useEffect(() => {
    if (!isConnected) {
      navigate(PoolLayaway.HOME);
    }
  }, [isConnected, chainId]);
  useEffect(() => {
    LocalhostStorage.set("pageCurrent", "order")
  },[])
  return (
    <HomepageTemplate>
      <TableWrapper>
        <OrderTable />
      </TableWrapper>
    </HomepageTemplate>
  );
};
