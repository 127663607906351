import { PoolLayaway } from "constants/routers";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "redux/reducers";
import { LocalhostStorage } from "utils/sessionStorage";

export const PrivateRoute = ({ children }) => {
  const { account } = useActiveWeb3React();
  const isConnected = Boolean(LocalhostStorage.get("isConnected"));
  return account && isConnected ? children : <Navigate to={PoolLayaway.HOME} />;

};
