import { PoolLayaway } from "constants/routers";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setPage } from "redux/slices/page";
import { useLocation } from "react-use";
import { ButtonWrapper,  NavWrapper } from "./styled";

export default function HomeSegment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useSelector((state: RootState) => state.page);
  const location = useLocation().pathname;
  useEffect(() => {
    if (location === PoolLayaway.POOL) dispatch(setPage("Pool"));
    if (location === PoolLayaway.ORDER) dispatch(setPage("Order"));
  }, [page]);

  return (
    <NavWrapper>
      <ButtonWrapper value={page} buttonStyle="solid">
        <Radio.Button
          onClick={() => {
            navigate(PoolLayaway.POOL);
            dispatch(setPage("Pool"));
          }}
          value="Pool"
        >
          Pool
        </Radio.Button>
        <Radio.Button
          onClick={() => {
            navigate(PoolLayaway.ORDER);
            dispatch(setPage("Order"));
          }}
          value="Order"
        >
          Order
        </Radio.Button>
      </ButtonWrapper>
    </NavWrapper>
  );
}
