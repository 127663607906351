import styled from "styled-components";
import { Button, Input, Pagination, Radio, Row, Table, Image } from "antd";
import AppColumn from "components/Column";
import { TextButton } from "components/TextButton";
import { SearchOutlined } from "@ant-design/icons";
import bgTable from "assets/images/bg-table.png";
import bgButton from "assets/vectors/vector-button.svg";
import prvIcon from "assets/icons/prev-icon.svg";
import nextIcon from "assets/icons/next-icon.svg";

export const TableStyled = styled(Table)`
  font-family: "Bicyclette Bold";
  margin-top: 40px;
  background-color: #fff;
  min-width: 80%;
  border-radius: 10px;
  min-height: 500px;
  text-align: center;
  background: url(${bgTable});
  background-size: cover;
  @media (max-width: 576px) {
    overflow: auto;
  }
  .ant-table {
    overflow: auto;
    border-radius: 10px;
    background: url(${bgTable});
    @media (max-width: 576px) {
      width: 1000px;
    }
  }
  .ant-table-cell {
    text-align: center;
    background-color: transparent;
    color: black;
    border-radius: 0px !important;
    border-bottom: 1px solid #626262;
  }
  .ant-table::-webkit-scrollbar {
    width: 6px;
    background-color: #f0f0f0;
  }

  .ant-table::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 6px;
  }

  && tbody > tr:hover > td {
    background-color: transparent;
  }
  .ant-pagination {
    .ant-pagination-item-active {
      background: #1890ff;
      color: white;
    }
  }
  th.ant-table-cell {
    font-size: 22px;
    font-weight: 700;
  }

  .ant-table-filter-trigger:hover {
    color: #ccc;
  }
  .ant-table-thead {
    background: transparent;
  }
  .ant-table-thead th.ant-table-column-sort {
    background: #191a1b;
  }
  .ant-table-thead th.ant-table-column-has-sorters:focus-visible {
    color: #fff;
  }
  .ant-table-tbody > tr > td {
    font-weight: 700;
    font-size: 16px;
  }
  @media (max-width: 1440px) {
    th.ant-table-cell {
      font-size: 20px;
    }
    .ant-table-tbody > tr > td {
      font-size: 15px;
    }
  }
  @media (max-width: 1024px) {
    th.ant-table-cell {
      font-size: 16px;
    }
  }
  @media (max-width: 820px) {
    .ant-table-tbody > tr > td {
      font-size: 13px;
    }
    th.ant-table-cell {
      font-size: 15px;
    }
  }
  @media (max-width: 576px) {
    .ant-table-filter-trigger {
      margin: 0;
    }
    th.ant-table-cell {
      font-size: 15px;
    }
    .ant-table-thead > tr > th {
      padding: 16px 0;
    }
  }
  .ant-table-column-title {
    flex: unset;
  }
  .ant-table-filter-column {
    justify-content: center;
  }
  .ant-table-filter-trigger {
    margin: 0px -8px -4px 4px;
  }

  .ant-empty-normal {
    color: #969696;
  }
`;

export const ValueStyled = styled.div`
  text-transform: uppercase;
`;

export const FilterFormWrapper = styled(AppColumn)`
  background: #000;
  padding: 15px;
  border-radius: 10px;
  min-width: 165px;
  .ant-space {
    justify-content: center;
  }

  .button-search {
    display: flex;
    align-items: center;
    background: #5180f4;
    border-radius: 10px;
    color: #fff;
    border: none;
    font-size: 13px;
    :hover,
    :focus {
      background: #5180f4;
      color: #fff;
    }
  }
  .button-reset {
    border-radius: 10px;
    font-size: 13px;
  }
  .ant-table-filter-trigger:hover {
    color: #ccc;
  }
  .ant-input {
    border-radius: 70px;
    font-size: 13px;
    border: #cff54d 1px solid;
    background: transparent;
    color: #fff;
    :hover,
    :focus {
      border: #cff54d 1px solid;
      outline: none;
      box-shadow: none;
    }
  }
  .ant-input-group-addon {
    background: transparent;
    color: #fff;
    border: #cff54d 1px solid;
    border-radius: 70px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    font-size: 12px;
    font-weight: 700;
  }
  .ant-input-group {
    max-width: 164px;
  }
  .button-search {
    justify-content: center;
    background: url(${bgButton});
    background-repeat: no-repeat;
    background-position: center;
    color: #cff54d;
    font-weight: 600;
    :hover {
      background-position: center !important;
      color: #cff54d;
      background: url(${bgButton});
      background-repeat: no-repeat;
    }
    :focus {
      background-position: center !important;
    }
  }
  .ant-checkbox-wrapper {
    color: #fff;
  }
  .ant-select {
    width: 100%;
    /* border: none; */
  }
  .ant-select-selector {
    border: none !important;
  }
`;
export const PaginationStyled = styled(Pagination)`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
  .ant-pagination-next .ant-pagination-item-link {
    background: transparent;
    border: none;
    .anticon-right {
      vertical-align: middle;
      svg {
        fill: rgba(207, 245, 77, 0.4);
      }
    }
  }
  .ant-pagination-prev .ant-pagination-item-link {
    background: transparent;
    border: none;
    .anticon-left {
      vertical-align: middle;
      svg {
        fill: rgba(207, 245, 77, 0.4);
      }
    }
  }

  .ant-pagination-item {
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    a {
      display: none;
    }
    .ant-image-img {
      opacity: 0.3;
    }
  }

  .ant-pagination-prev {
    background: url(${prvIcon});
  }
  .ant-pagination-next {
    background: url(${nextIcon});
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    background-repeat: no-repeat;
    background-position: center;
    .ant-image-img {
      display: none;
    }
  }

  .ant-pagination-disabled {
    opacity: 0.5;
  }

  .ant-pagination-item-active {
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    .ant-image-img {
      opacity: 1;
    }
    a {
      display: none;
    }
    border: none;
  }
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #cff54d;
  }
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #cff54d;
  }
  .ant-pagination-jump-next .ant-pagination-item-link,
  .ant-pagination-jump-prev .ant-pagination-item-link {
    display: block !important;
  }
`;

export const SubPagination = styled(Pagination)`
  .ant-pagination-simple-pager {
    font-size: 12px;
    color: #fff;
    input {
      border: #cff54d 1px solid;
      background: transparent;
      &:hover,
      &:focus-visible {
        border: #cff54d 1px solid;
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: none;
  }
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
  margin-left: 8px;
`;

export const WrapperAction = styled(Row)`
  width: 200px;
`;

export const RadioGroupColumn = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const InputWrapper = styled(Input)`
  margin-bottom: 16px;
  display: block;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ButtonWrapper = styled(Button)`
  width: 90px;
  /* background: url(${bgButton}) !important; */
  background-repeat: no-repeat;
  background-position: center;

  margin-top: 20px;
  :focus {
    background: url(${bgButton}) !important;
    background-repeat: no-repeat !important;
    background-position: center;
  }
`;

export const IconSearch = styled(SearchOutlined)`
  color: ${(props) => props.color || undefined};
`;

export const FilterButton = styled(TextButton)`
  svg {
    margin-left: 4px;
    margin-bottom: 4px;
  }
`;

export const TableWrapper = styled.div`
  margin-bottom: 80px;
  .ant-dropdown-menu-item:hover {
    background-color: #000;
  }
  .ant-checkbox-inner {
    background-color: #000;
  }
`;

export const CheckboxIcon = styled(Image)`
  width: 25px;
`;

export const SelectItem = styled(Row)`
  position: absolute;
  left: 32px;
  top: 5px;
  width: 120px;
`;
export const TextSelect = styled.p`
  padding-left: 28px;
  width: 60%;
  font-size: 13px;
`;

export const IconFilter = styled.div`
  width: 12px;
  cursor: pointer;
  /* margin-left: 10px; */
  display: flex;
  img {
    width: 100%;
  }
`;

export const SelectAssetPopup = styled.div`
  background-color: #000;
  position: absolute;
  z-index: 100;
  top: 34px;
  right: -14px;
  width: 150px;
  height: 100px;
  padding: 10px;
  border-radius: 10px;
  .ant-select {
    width: 100%;
    /* border: none; */
  }
  .ant-select-selector {
    border: none !important;
  }
`;

export const FilterTitle = styled(Row)`
  position: relative;
`;
