export const CHAIN_NETWORK = {
  [42]: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    base_url: 'https://kovan.etherscan.io',
    node: process.env.REACT_APP_KOVAN_NODE,
    chain_name: 'Kovan Test Network',
  },
  [97]: {
    name: 'BNB',
    symbol: 'bnb',
    decimals: 18,
    base_url: 'https://testnet.bscscan.com',
    node: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    chain_name: 'BNB Smart Chain Testnet',
  },
  [56]: {
    name: 'BNB',
    symbol: 'bnb',
    decimals: 18,
    base_url: 'https://bscscan.com',
    node: 'https://bsc-dataseed.binance.org',
    chain_name: 'BNB Smart Chain Mainnet',
  },
  [43113]: {
    name: 'AVAX',
    symbol: 'avax',
    decimals: 18,
    base_url: 'https://testnet.explorer.avax.network/',
    node: process.env.REACT_APP_AVALANCHE_NODE,
    chain_name: 'Avalanche FUJI Testnet RPC',
  },
  [80001]: {
    name: 'POLYGON',
    symbol: 'matic',
    decimals: 18,
    base_url: 'https://mumbai.polygonscan.com/',
    node: process.env.REACT_APP_POLYGON_NODE,
    chain_name: 'Polygon Testnet',
  },
  [770]: {
    name: 'GSC',
    symbol: 'GNI',
    decimals: 18,
    base_url: 'http://14.161.39.254:20940/',
    node: process.env.REACT_APP_GSC_NODE,
    chain_name: 'GSC Testnet',
  },
};

export const destinationId = {
  [770]: '100',
  [42]: '99',
  [43113]: '97',
  [97]: '98',
  [56]: '98',
  [80001]: '96',
};
