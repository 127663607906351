import { Modal, Row, Image, Button } from "antd";
import styled from "styled-components";
export const PaymentModalWrapper = styled(Modal)`
  top: calc(calc(100% - 400px) / 2);
  .ant-modal-content {
    background: #000;
    border-radius: 10px;
    border: 1px solid #cff54d;
    .ant-modal-body {
      padding: 18px;
    }
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 56px;
  .ant-btn:before{
    background: transparent;
  }
`;


export const TitleStyled = styled.p`
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  @media(min-width: 1400px){
    font-size: 24px;
  }
  @media(min-width: 1600px){
    font-size: 26px;
  }
`;

export const PaymentContent = styled.p`
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  @media(min-width: 1400px){
    font-size: 16px;
  }
  @media(min-width: 1600px){
    font-size: 17px;
  }
`;

export const LineTitle = styled(Image)`
  width: 84px;
  display: block;
  position: absolute;
  bottom: 9px;
  right: -3px;
  height: 20px;
  @media (min-width: 1400px){
    width: 94px;
  }
  @media (min-width: 1600px){
    width: 104px;
    bottom: 14px;
  }
`;

export const ButtonStyled = styled(Button)<any>`
  border-radius: 10px;
  width: 100%;
  border: none;
  font-weight: 500;
  height: 40px;
  display: flex;
  margin-top: -23px;
  background: transparent !important;
  color: #cff54d !important;
  justify-content: center;
  align-items: center;
  :disabled,
  :disabled:hover {
    background-color: transparent;
    color: #ccc !important;
    background-image: none !important;
  }
  :hover, :focus {
    background: transparent;
    color: #cff54d;
  }
`;

export const ButtonBackground = styled(Image)`
  position: relative;
  top: 6px;
`;

export const WrapperButton = styled.div`
text-align: center;
  .ant-image {
    height: 0;
  }
  .ant-btn-loading:before {
    display: none !important;
  }
`;
