import { Button } from "antd";
import styled from "styled-components";
import bgPayment from "assets/vectors/bg-payment-button.svg"
export const TwitterButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 46px;
  background: #e4e4e4;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 75px;
`;

export const TextTwitterBtn = styled.p`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 14px;
  text-transform: capitalize;
  color: #000;
  img {
    margin-right: 10px;
  }
`;

export const OrderButtonStyled = styled(Button)<any>`
  background: ${(p) => (p.action === "cancel" ? "#CFF54D" : "#9600B0")};
  border-radius: 34px;
  color:${(p) => (p.action === "cancel" ? "#000" : "#fff")};
  border: none;
  font-size: 14px;
  margin-right: 16px;
  font-weight: 700;
  width: 100%;
  :hover,
  :focus {
    background: ${(p) => (p.action === "cancel" ? "#CFF54D" : "#9600B0")};
    color:${(p) => (p.action === "cancel" ? "#000" : "#fff")};
  }
  :disabled,
  :disabled:hover {
    border: #ccc 1px solid !important;
    background-image: linear-gradient(#242526, #000) !important;
    color: white;
  }
  
`;

