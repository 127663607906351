import AppColumn from "components/Column";
import styled from "styled-components";
import { Checkbox, Form } from "antd";
import { Select } from "antd";

export const LargeTitleLabel = styled.div`
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
`;

export const FilterWrapper = styled(AppColumn)`
  background-color: #000;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  color: black;
  width: 100% !important;
  display: flex;
  justify-content: center;
  @media (max-width: 820px) {
    width: fit-content;
    background-color: #000;
    height: auto;
    margin-bottom: 50px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const ColumnCheckbox = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    display: flex;
    margin-right: 0;
  }
  .ant-checkbox + span {
    font-weight: 600;
    font-size: 15px;
  }
`;

export const SelectWrapper = styled(Select)`
  border-radius: 10px;
  border: 1px solid #9600B0;
  color: black;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  border-radius: 24px;
  color: #fff;
  .ant-select-selector {
    background: transparent !important;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-search-input  {
    color: #9600B0;
  }
  .ant-select-arrow {
    color: #9600B0;
  }

  .ant-select-clear {
    span {
      position: absolute;
      right: 0px;
    }
  }
  .ant-select-clear{
    background: transparent;
    right: 38px;
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
  padding: 0px;
`;

export const FormItemButton = styled(Form.Item)`
  margin-bottom: 0px;
`;
