import { ModalStyled, Wrapper,WrapperImage,WrapperText } from "./styled";
import iconFailed from "assets/icons/x.png"
export const FailedModal = ({ onCancel, visible }) => {
  return (
    <ModalStyled visible={visible} onCancel={onCancel} footer={false}>  
    <Wrapper>
        <WrapperImage src={iconFailed} alt="success" />
      </Wrapper>    
      <Wrapper>
        <WrapperText>Your transaction was failed!</WrapperText>
      </Wrapper>
    </ModalStyled>
  );
};
