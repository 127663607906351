import { Drawer } from "antd";
import styled from "styled-components";

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #000;
  }
  .ant-drawer-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    color: #CFF54D;

  }
  .ant-drawer-close {
    position: absolute;
    right: 0;
    margin-right: 5px;
  }
  .ant-drawer-header {
    padding: 16px 10px;
    background-color: #000;
    border: none;
  }
  .ant-form-item-label > label{
    color: #CFF54D;
  }
  @media (max-width: 360px) {
    .ant-drawer-content-wrapper {
      width: 320px !important;
    }
  }
  .ant-drawer-close{
    svg{
      fill: #CFF54D;
    }
  }
`;
