import { Modal } from "antd";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  max-width: 285px;
  border-radius: 50%;
  height: 285px;
  top: calc(calc(100% - 400px) / 2);
  .ant-modal-content {
    background: #000;
    border-radius: 50%;
    display: flex;
    height: 285px;
    align-items: center;
    justify-content: center;
    border: red 1px solid;

    .ant-modal-body {
      border-radius: 10px;
      border-radius: 50%;

    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const WrapperImage = styled.img`
  width: 108px;
  height: 108px;
`;

export const WrapperText = styled.p`
  margin-top: 30px;
  color: red;
  font-weight: 600;
  font-size: 14px;
`;
