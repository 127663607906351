import { Form } from "antd";
import styled from "styled-components";

export const StyledForm = styled(Form)<{
  labelcolor?: string;
}>`
  & > *:not(:first-child) {
    margin-top: 10px;
  }

  .ant-form-item-label > label {
    color: ${({ labelcolor }) => labelcolor || "#000"};
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }
  @media(min-width: 1400px){
    .ant-form-item-label > label {
      font-size: 16px;
    }
  }
  @media(min-width: 1600px){
    .ant-form-item-label > label {
      font-size: 19px;
    }
  }
  @media(max-width: 576px){
    .ant-form .ant-form-item .ant-form-item-control{
      flex: unset;
    }
  }
`;
