import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useCallback } from "react";
import { useLayawayRouterContract } from "./useLayawayRouterContract";
import { CONVERT_2_NUMBER, PRICE_DECIMAL_PART } from "constants/value";
import { BigNumber } from "ethers";

const useLaywayRouterCallback = () => {
  const { account } = useActiveWeb3React();
  const layawayRouter = useLayawayRouterContract();

  const createOrder = useCallback(
    async (
      layawayPoolAddress: string,
      purchaseAmount: BigNumber,
      term: number
    ) => {
      if (layawayRouter && account) {
        const gasLimit = await layawayRouter.estimateGas.createOrder(
          layawayPoolAddress,
          purchaseAmount,
          term
        );
        const tx = await layawayRouter.createOrder(
          layawayPoolAddress,
          purchaseAmount,
          term,
          {
            gasLimit: BigNumber.from(gasLimit).mul(2),
          }
        );
        return tx.wait(1);
      }
    },
    [account]
  );

  const createOrderETH = useCallback(
    async (
      layawayPoolAddress: string,
      purchaseAmount: BigNumber,
      term: number,
      collateralValue: number
    ) => {
      if (collateralValue && layawayRouter && account) {
        const gasLimit = await layawayRouter.estimateGas.createOrder(
          layawayPoolAddress,
          purchaseAmount,
          term,
          {
            value: collateralValue,
          }
        );
        const tx = await layawayRouter.createOrder(
          layawayPoolAddress,
          purchaseAmount,
          term,
          {
            value: collateralValue,
            gasLimit: BigNumber.from(gasLimit).mul(2),
          }
        );
        return tx.wait(1);
      }
    },
    [account]
  );

  const cancelOrder = useCallback(
    async (orderId: string) => {
      if (layawayRouter && account) {
        const gasLimit = await layawayRouter.estimateGas.cancelOrder(
          Number(orderId)
        );
        const tx = await layawayRouter.cancelOrder(Number(orderId), {
          gasLimit: BigNumber.from(gasLimit).mul(2),
        });
        return tx.wait(1);
      }
    },
    [account]
  );

  const executeOrder = useCallback(
    async (orderId: string) => {
      if (layawayRouter && account) {
        const gasLimit = await layawayRouter.estimateGas.executeOrder(
          Number(orderId)
        );
        const tx = await layawayRouter.executeOrder(Number(orderId), {
          gasLimit: BigNumber.from(gasLimit).mul(2),
        });
        return tx.wait(1);
      }
    },
    [account]
  );
  const executeOrderETH = useCallback(
    async (orderId: string, payingSecondTime: BigNumber) => {
      if (payingSecondTime && layawayRouter && account) {
        const gasLimit = await layawayRouter.estimateGas.executeOrder(
          Number(orderId),
          {
            value: payingSecondTime,
          }
        );
        const tx = await layawayRouter.executeOrder(Number(orderId), {
          value: payingSecondTime,
          gasLimit: BigNumber.from(gasLimit).mul(2),
        });
        return tx.wait(1);
      }
    },
    [account]
  );

  const createLayawayPool = useCallback(
    async (sellToken: string, payToken: string, price) => {
      if (layawayRouter && account) {
        const gasLimit = await layawayRouter.estimateGas.createLayawayPool(
          sellToken,
          payToken,
          Number(price) * PRICE_DECIMAL_PART
        );
        const tx = await layawayRouter.createLayawayPool(
          sellToken,
          payToken,
          Number(price) * PRICE_DECIMAL_PART,
          {
            gasLimit: BigNumber.from(gasLimit).mul(2),
          }
        );
        return tx.wait(1);
      }
    },
    [account]
  );

  const getCollateral = useCallback(async () => {
    try {
      if (layawayRouter && account) {
        const tx = await layawayRouter.collateral();
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account]);

  const poolExist = useCallback(
    async (sellToken: string, payToken: string, price) => {
      try {
        if (layawayRouter && account) {
          const tx = await layawayRouter.pools(
            sellToken,
            payToken,
            Number(price) * PRICE_DECIMAL_PART
          );
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account]
  );

  const getFirstPayAmount = useCallback(
    async (
      purchaseAmount: BigNumber,
      price,
      sellToken: string,
      payToken: string
    ) => {
      try {
        if (layawayRouter && account) {
          const tx = await layawayRouter.getFirstPayAmount(
            purchaseAmount,
            Number(price) * PRICE_DECIMAL_PART,
            sellToken,
            payToken
          );
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account]
  );

  return {
    createOrder,
    cancelOrder,
    executeOrder,
    createLayawayPool,
    getCollateral,
    poolExist,
    createOrderETH,
    executeOrderETH,
    getFirstPayAmount,
  };
};

export default useLaywayRouterCallback;
