import { BigNumber, ethers } from "ethers";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useCallback } from "react";
import { usePoolTokenContract } from "./usePoolTokenContract";
const usePoolTokenCallback = (poolAddress: string) => {
  const { account } = useActiveWeb3React();
  const poolToken = usePoolTokenContract(poolAddress);
  const balanceOf = useCallback(
    async (account: string) => {
      try {
        if (poolToken && account) {
          const tx = await poolToken.balanceOf(account);
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, poolAddress]
  );

  const claimable = useCallback(async () => {
    try {
      if (poolToken && account) {
        const tx = await poolToken.claimable(account);
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, poolAddress]);

  const deposit = useCallback(
    async (depositAmount: BigNumber) => {
      if (depositAmount && poolToken && account) {
        const gasLimit = await poolToken.estimateGas.deposit(depositAmount, {});
        const tx = await poolToken.deposit(depositAmount, {
          gasLimit: BigNumber.from(gasLimit).mul(2),
        });
        return tx.wait(1);
      }
    },
    [account, poolAddress]
  );

  const depositETH = useCallback(
    async (depositAmount: BigNumber) => {
      if (depositAmount && poolToken && account) {
        const gasLimit = await poolToken.estimateGas.deposit(depositAmount, {
          value: depositAmount,
        });
        const tx = await poolToken.deposit(depositAmount, {
          value: depositAmount,
          gasLimit: BigNumber.from(gasLimit).mul(2),
        });
        return tx.wait(1);
      }
    },
    [account, poolAddress]
  );

  const getAvailableWithdraw = useCallback(async () => {
    try {
      if (poolToken && account) {
        const tx = await poolToken.getAvailableWithdraw(account);
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, poolAddress]);

  const withdraw = useCallback(
    async (withdrawAmount: BigNumber) => {
      if (poolToken && account) {
        const gasLimit = await poolToken.estimateGas.withdraw(withdrawAmount);
        const tx = await poolToken.withdraw(withdrawAmount, {
          gasLimit: BigNumber.from(gasLimit).mul(2),
        });
        return tx.wait(1);
      }
    },
    [account, poolAddress]
  );

  const claim = useCallback(async () => {
    if (poolToken && account) {
      const gasLimit = await poolToken.estimateGas.claim();
      const tx = await poolToken.claim({
        gasLimit: BigNumber.from(gasLimit).mul(2),
      });
      return tx.wait(1);
    }
  }, [account, poolAddress]);

  const poolInfo = useCallback(async () => {
    try {
      if (poolToken && account) {
        const tx = await poolToken.poolInfo();
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, poolAddress]);
  return {
    balanceOf,
    claimable,
    deposit,
    depositETH,
    getAvailableWithdraw,
    withdraw,
    claim,
    poolInfo,
  };
};

export default usePoolTokenCallback;
