import { Modal } from "antd";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  max-width: 285px;
  border-radius: 50%;
  height: 285px;
  top: calc(calc(100% - 400px) / 2);
  .ant-modal-content {
    background: #000;
    border-radius: 50%;
    height: 285px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #cff54d 1px solid;
    .ant-modal-body {
      border-radius: 50%;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const WrapperImage = styled.img`
  width: 108px;
  height: 108px;
`;

export const WrapperText = styled.p`
  margin-top: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #cff54d;
  padding: 0 10px;
`;
