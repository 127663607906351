import { Form, notification, Row } from "antd";
import { StyledForm } from "components/StyledForm";
import { ContractType } from "constants/enum/ContractType";
import { EventType } from "constants/enum/EventType";
import { ethers } from "ethers";
import usePoolTokenCallback from "hooks/pool-token/usePoolTokenCallback";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useEffect, useState } from "react";
import { postContractAction } from "services/api/contract_action";
import { blockInvalidChar, formatNumber, formatNumberThousand } from "utils/formatNumber";
import { roundNumber } from "utils/roundNumber";
import { toast } from "react-toastify";
import { RightInput } from "../DepositModal/styled";
import {
  WithdrawModalWrapper,
  LabelWrapper,
  WrapperContent,
  LabelValueWrapper,
  InputWrapper,
  WrapperButton,
  InputField,
  Wrapper,
  TitleStyled,
  WithdrawButton,
  FormItem,
  FormItemInput,
  WrapperText,
  NoteText,
  ButtonBackground,
} from "./styled";
import bgButton from "assets/vectors/vector-withdraw-button.svg";
export const WithdrawModal = ({
  poolAddress,
  sellToken,
  payToken,
  visible,
  success,
  failed,
  onCancel,
  refresh,
}) => {
  const { account, chainId } = useActiveWeb3React();
  const { getAvailableWithdraw, withdraw } = usePoolTokenCallback(poolAddress);

  const [availableWithdraw, setAvailableWithdraw] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputText, setInputText] = useState(undefined);
  const CONVERT_2_NUMBER = 10 ** parseFloat(sellToken?.decimal);
  const [form] = Form.useForm();

  const handleButtonMax = () => {
    setInputText(ethers.utils.formatUnits(availableWithdraw || 0, sellToken?.decimal || 1));
  };

  const handleWithdraw = async () => {
    try {
      setLoading(true);
      const res = await withdraw(
        ethers.utils.parseUnits(inputText, parseFloat(sellToken?.decimal))
      );

      if (!!res?.transactionHash) {
        const rs = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Pool,
          contractAddress: poolAddress,
          eventName: EventType.Withdrawed,
        });
        if (!!rs) {
          success();
          refresh();
        } else {
          failed();
        }
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      toast.error(e?.reason || e?.message || "User denied transaction signature!!");
    }
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    if (values) {
      handleWithdraw();
    }
  };
  useEffect(() => {
    void (async () => {
      if (visible) {
        const res = await getAvailableWithdraw();
        if (res) setAvailableWithdraw(res);
      }
    })();
    return () => {
      setInputText(null);
    }
  }, [account, chainId, poolAddress, visible]);

  const handleInputChange = (value) => {
    let temp = value;
    value =
      temp.indexOf(".") >= 0
        ? temp.substr(0, temp.indexOf(".")) + temp.substr(temp.indexOf("."), sellToken?.decimal || 6)
        : temp;
    setInputText(value);
  };

  return (
    <WithdrawModalWrapper
      visible={visible}
      onCancel={onCancel}
      footer={false}
      closable={false}
    >
      <Wrapper>
        <TitleStyled>Withdraw</TitleStyled>
      </Wrapper>

      <WrapperText>
        <LabelWrapper>Available amount to withdraw</LabelWrapper>
        <LabelValueWrapper>
          {formatNumberThousand(ethers.utils.formatUnits(availableWithdraw || 0, sellToken?.decimal || 1) || 0)}{" "}
          {sellToken?.symbol?.toUpperCase()}
        </LabelValueWrapper>
      </WrapperText>

      <WrapperContent>
        <StyledForm
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          labelcolor="#fff"
          form={form}
        >
          <FormItemInput name={["amount"]} label="">
            <InputWrapper>
              <InputField
                value={inputText}
                type={"number"}
                onKeyDown={blockInvalidChar}
                bordered={false}
                onChange={(e) => {handleInputChange(e.target.value);}}
                disabled={loading}
              ></InputField>
              <RightInput>
                <LabelWrapper>{sellToken?.symbol?.toUpperCase()}</LabelWrapper>
                <WrapperButton disabled={loading} onClick={handleButtonMax}>
                  Max
                </WrapperButton>
              </RightInput>
            </InputWrapper>
          </FormItemInput>

          <FormItem>
            <NoteText>
              *Note: The converted value may change according to the price on
              the market price over time.
            </NoteText>
          </FormItem>

          <FormItem>
            <ButtonBackground src={bgButton} alt="" preview={false} />
            <Row justify="center">
              <WithdrawButton
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={
                  !inputText ||
                  Number(inputText) <= 0
                  ||
                  Number(inputText) >
                  Number(availableWithdraw) / CONVERT_2_NUMBER
                }
              >
                Withdraw
              </WithdrawButton>
            </Row>
          </FormItem>
        </StyledForm>
      </WrapperContent>
    </WithdrawModalWrapper>
  );
};
