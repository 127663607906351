import { ethers } from 'ethers';

declare global {
  interface Window {
    ethereum: any;
  }
}

const signMessage = async (account, providerWallet) => {
  try {
    const message = `If you click the 'sign' button, you agree to authorize us to make this transaction.
		Your Wallet Address: ${account}
		Timestamp: ${Date.now()}
		`;

    const provider = new ethers.providers.Web3Provider(providerWallet);
    const signers = provider.getSigner();
    const signature = await signers.signMessage(message);
    return {
      success: true,
      data: {
        signature,
        message,
        account,
      },
    };
  } catch (e) {
    return {
      success: false,
      error: e.message,
    };
  }
};

export default signMessage;
