import { ContractType } from "constants/enum/ContractType";
import { EventType } from "constants/enum/EventType";
import usePoolTokenCallback from "hooks/pool-token/usePoolTokenCallback";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useEffect, useState } from "react";
import { postContractAction } from "services/api/contract_action";
import { CONVERT_2_NUMBER } from "constants/value";
import vector from "assets/vectors/vector-disconnect.svg";
import bgButton from "assets/vectors/vector-claim-button.svg";
import { Row } from "antd";

import {
  LabelWrapper,
  WrapperContent,
  LabelValueWrapper,
  ClaimModalWrapper,
  ClaimButton,
  HeadlineStyled,
  LineTitle,
} from "./styled";
import { TitleStyled, Wrapper, ButtonWrapper } from "../DepositModal/styled";
import { ButtonBackground } from "../CreateOrderModal/styled";
import { formatNumber } from "utils/formatNumber";
import { roundDown } from "utils/roundNumber";
import { toast } from "react-toastify";
import { formatUnits } from "ethers/lib/utils";

export const ClaimModal = ({
  poolAddress,
  visible,
  success,
  failed,
  onCancel,
  payToken,
  refresh,
}) => {
  const { account, chainId } = useActiveWeb3React();
  const { claim, claimable } = usePoolTokenCallback(poolAddress);
  const [loading, setLoading] = useState<boolean>(false);
  const [profit, setProfit] = useState(undefined);

  const handleClaim = async () => {
    try {
      setLoading(true);
      const res = await claim();

      if (!!res?.transactionHash) {
        const rs = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Pool,
          contractAddress: poolAddress,
          eventName: EventType.Claimed,
        });
        if (!!rs) {
          success();
          refresh();
        } else {
          failed();
        }
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      toast.error(e?.reason || e?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    void (async () => {
      const resClaim = await claimable();
      setProfit(resClaim);
    })();
  }, [account, chainId, poolAddress, visible]);

  const calcProfit = () => {
    let profitCalc = profit / 10 ** parseFloat(payToken?.decimal);
    if (profitCalc > 10) {
      return roundDown(profitCalc, 2);
    } else {
      return roundDown(profitCalc, 4);
    }
  };

  return (
    <ClaimModalWrapper
      visible={visible}
      onCancel={onCancel}
      footer={false}
      closable={false}
    >
      {/* <HeadlineStyled style={{ textAlign: "center" }}>Claim</HeadlineStyled> */}
      <Wrapper>
        <TitleStyled>Claim</TitleStyled>
        <LineTitle src={vector} alt="" preview={false} />
      </Wrapper>
      <WrapperContent justify="space-between">
        <LabelWrapper>Your Income</LabelWrapper>
        <LabelValueWrapper>
          {formatUnits(profit || 0, payToken?.decimal) || 0}{" "}
          {payToken?.name.toUpperCase()}
        </LabelValueWrapper>
      </WrapperContent>
      <ButtonWrapper>
        <ButtonBackground src={bgButton} alt="" preview={false} />
        <Row justify="center">
          <ClaimButton loading={loading} onClick={handleClaim}>
            Claim
          </ClaimButton>
        </Row>
      </ButtonWrapper>
    </ClaimModalWrapper>
  );
};
