import { Menu } from "antd";
import { MenuStyled, StartDivider } from "styles/Common/UserMenu.styled";
import DisconnectItem from "components/disconnect/disconnect";
import UserItem from "./UserLogged";

export const UserMenu = (props) => {
  return (
    <MenuStyled>
      <Menu>
        <UserItem key="user-nav" {...props} />
        <DisconnectItem key="disconnect" {...props} />
      </Menu>
    </MenuStyled>
  );
};
