import styled from "styled-components/macro";

export const AppRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const RowCenter = styled(AppRow)`
  width: 100%;
  align-items: center;
`;

export const RowBetween = styled(AppRow)`
  justify-content: space-between;
  width:100%;
  margin-top: 4px;
`;

export default AppRow;
