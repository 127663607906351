import styled from 'styled-components';
import {  Menu, Space } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { truncateText } from 'utils/helper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ItemMenu } from './styled';
// import { toast } from 'react-toastify';

const { Item } = Menu;

const AccountHighlight = styled.span`
  color: #fff !important;
  font-size: 15px;
  font-weight: 600;
  line-height: unset !important;
  text-transform: lowercase;
  letter-spacing: 0.08rem;
`;

const UserItem = props => {
  return (
    <>
      <ItemMenu key='account-user'>
        <CopyToClipboard
          onCopy={() => {
            // toast.success('COPY successfully');
          }}
          text={props?.account}
        >
          <Space align='center'>
            <AccountHighlight>{truncateText(props?.account, 6, 4)}</AccountHighlight>
            {/* <CopyOutlined size={16} /> */}
          </Space>
        </CopyToClipboard>
      </ItemMenu>
    </>
  );
};

export default UserItem;
