import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSignar: false,
};

const SignarSlice = createSlice({
  name: "signar",
  initialState,
  reducers: {
    setSignar: (state, { payload }) => {
      state.isSignar = payload;
    },
  },
});
export const { setSignar } = SignarSlice.actions;

export default SignarSlice.reducer;
