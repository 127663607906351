import { Form, Modal, Image, Input } from "antd";
import { TextButton } from "components/TextButton";
import { TextInputField } from "components/TextInputField";
import styled from "styled-components";
import { Select, Row } from "antd";
import bgButton from "assets/vectors/vector-button.svg";
const { Option } = Select;

export const CreatePoolModalWrapper = styled(Modal)`
  top: calc(calc(100% - 400px) / 2);
  @media (min-width: 1400px) {
    min-width: 620px;
  }
  @media (min-width: 1600px) {
    min-width: 700px;
  }
  .ant-modal-content {
    background: #000;
    border-radius: 10px;
    border: #cff54d 1px solid;
    padding: 0 10px;
  }
  .ant-modal-body {
    background: #000;
    border-radius: 10px;
  }
  .ant-form-item-label {
    font-size: 10px;
    font-weight: 400;
    color: white;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .ant-form-item-label {
    padding: 0 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 56px;
`;

export const WrapperContent = styled.div`
  /* margin-bottom: 20px; */
`;
export const WrapperLabel = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  @media (min-width: 1400px) {
    font-size: 18px;
  }
  @media (min-width: 1600px) {
    font-size: 20px;
  }
`;

export const TitleStyled = styled.p`
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  @media (min-width: 1400px) {
    font-size: 24px;
  }
  @media (min-width: 1600px) {
    font-size: 26px;
  }
`;

export const BottomButton = styled(TextButton)`
  border-radius: 10px;
  /* width: 100%; */
  height: 40px;
  display: flex;
  margin-top: -16px;
  background: transparent !important;
  color: #cff54d !important;
  justify-content: center;
  align-items: center;
  :disabled,
  :disabled:hover {
    background-color: transparent;
    opacity: 0.6;
    background-image: none !important;
  }
  :hover,
  :focus,
  :active {
    background: transparent;
    color: #cff54d;
  }
  .ant-btn:before {
    background: transparent;
  }
  @media (min-width: 1400px) {
    font-size: 16px;
  }
  @media (min-width: 1600px) {
    font-size: 18px;
  }
`;

export const SelectWrapper = styled(Select)`
  border: 1px solid #cff54d;
  border-radius: 24px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  padding: 4px 0;
  .ant-select-selector {
    background: transparent !important;
    border: none;
    box-shadow: none !important;
  }
  .ant-select-selection-placeholder {
    color: #d9d9d9;
  }
  .ant-select-arrow {
    color: #ffffff;
  }

  .ant-select-selection-item {
    color: #ffffff !important;
  }
  @media (max-width: 576px) {
    width: 100%;
    flex: unset;
  }

  .ant-select-clear {
    background: transparent;
    span {
      position: absolute;
      right: 0px;
      color: #fff;
    }
    svg {
      fill: #ccc;
      width: 20px;
    }
    right: 13px;
    width: 16px;
    height: 22px;
    margin-top: -8px;
  }
  .ant-select-selection-item {
    justify-content: space-between;
    width: unset;
    padding-right: 24px !important;
    padding-left: 10px;
    display: flex;
    ::after {
      display: none !important;
    }
  }
`;

export const FormItemSelect = styled(Form.Item)`
  width: 45%;
  margin-top: 14px;
  margin-bottom: 0px;
  padding: 0px;
  display: block;
`;

export const FormItemWrapper = styled(Form.Item)`
  margin: 0;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const FormItemInput = styled(Form.Item)`
  margin-bottom: 0px;
  padding: 0px;
`;

export const FormItemButton = styled(Form.Item)`
  margin: 0;
  text-align: center;
  .ant-image {
    height: 0;
  }
  .ant-btn:before {
    background: transparent;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #ccc;
  }
`;

export const AddButtonWrapper = styled.div`
  background: transparent;
  border: #9600b0 1px solid;
  border-radius: 10px;
  color: #cff54d;
  padding: 4px 17px;
  /* position: absolute;
  bottom: 0; */
  margin-top: 10px;
  white-space: unset;
  @media (max-width: 576px) {
    padding: 4px 8px;
    text-align: center;
  }
`;

export const LineTitle = styled(Image)`
  width: 110px;
  display: block;
  position: absolute;
  bottom: 7px;
  right: -6px;
  height: 20px;
  @media (min-width: 1400px) {
    width: 130px;
  }
  @media (min-width: 1600px) {
    width: 150px;
  }
`;

export const ButtonBackground = styled(Image)`
  position: relative;
  top: 20px;
`;

export const TokenPoolRow = styled(Option)`
  width: 100px;
  display: flex;
`;

export const ViewMore = styled.p`
  color: #cff54d;
`;

export const InputWrapper = styled.div`
  width: 100%;
  border-radius: 24px;
  border: 1px solid #cff54d;
  background-color: transparent;
  line-height: 18px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 8px 1px 0px;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus,
  .ant-input:focus {
    box-shadow: none;
  }
`;

export const InputField = styled(Input)`
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  :disabled,
  :disabled:hover {
    background-color: transparent !important;
    color: #fff !important;
  }
  .ant-input {
    background-color: transparent;
    color: white;
    font-weight: 600;
  }
`;

export const WrapperInput = styled(TextInputField)`
  border: 1px solid #cff54d;
  color: #fff;
  ::placeholder {
    color: #fff;
  }
  :focus,
  :hover {
    border-color: #cff54d !important;
  }
  :disabled,
  :disabled:hover {
    background-color: transparent !important;
    color: #fff !important;
  }

  .ant-input-clear-icon,
  .anticon.ant-input-clear-icon {
    color: #fff;
    display: flex;
    justify-content: center;
  }
  .ant-input {
    text-align: left;
  }
  @media (min-width: 1400px) {
    font-size: 16px;
  }
  @media (min-width: 1600px) {
    font-size: 18px;
  }
`;
