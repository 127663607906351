import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    collateral: undefined,
  };
  
  const CollateralSlice = createSlice({
    name: "collateral",
    initialState,
    reducers: {
      setCollateral: (state, {payload}) => {
          state.collateral = payload;
      },
  
    },
  });
  export const { setCollateral } = CollateralSlice.actions;
  
  export default CollateralSlice.reducer;
  