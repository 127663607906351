import { TextInputField } from "components/TextInputField";
import React from "react";

interface NumericInputProps {
  suffix?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
}


export const NumericInput = (props: NumericInputProps) => {
  const { placeholder, suffix, value, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      onChange(inputValue);
    }
  };


  return (
    <TextInputField
      {...props}
      suffix={suffix}
      onChange={handleChange}
      placeholder={placeholder}
      maxLength={25}
      placeHolderColor={"#fff"}
      type={"number"}
    />
  );
};
