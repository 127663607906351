import {
  Col,
  Dropdown,
  Empty,
  Menu,
  MenuProps,
  PaginationProps,
  Row,
  Space,
  Spin,
  Image,
} from "antd";
import PoolItem from "components/PoolItem";
import { useEffect, useState } from "react";
import HomepageTemplate from "template/Homepage";
import { useMediaQuery } from "react-responsive";
import {
  GridItems,
  ColItems,
  WapperPool,
  WrapperButton,
  SpinWrapper,
  WrapperCol,
  StyledPagination,
  ActionWrapper,
  EmptyWrapper,
  FilterButton,
  RowFooter,
  FilterLeftStyled,
  ButtonRightStyled,
  VectorCreate,
  ImgFooter,
  ListPoolWrapper,
  SubPagination,
} from "./styled";
import { IPool, PoolStatus } from "services/api";
import { ApplicationModal } from "redux/slices/application";
import { useToggleModal, useModalOpen } from "redux/slices/application/hook";
import { CreatePoolModal } from "components/modal/CreatePoolModal";
import { TextButton } from "components/TextButton";
import useSalePool from "hooks/pool-token/useSalePool";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import useLaywayRouterCallback from "hooks/layaway-router/useLayawayRouterCallback";
import { setCollateral } from "redux/slices/collateral";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { StyledDrawer } from "components/StyledDrawer";
import { PoolFilterDrawer } from "components/PoolFilterDrawer";
import { PoolFilter } from "components/PoolFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import vector from "assets/vectors/vector-create-button.svg";
import bgFooter from "assets/images/footer.png";
import { PoolLayaway } from "constants/routers";
import { LocalhostStorage } from "utils/sessionStorage";
import iconPagi1 from "assets/vectors/vector-pagi-1.svg";
import iconPagi2 from "assets/vectors/vector-pagi-2.svg";
import iconPagi3 from "assets/vectors/vector-pagi-3.svg";
import { debounce } from "utils/debounce";
import prvIcon from "assets/icons/prev-icon.svg";
import nextIcon from "assets/icons/next-icon.svg";

export const PoolPage = () => {
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useDispatch();

  const toggleCreatePool = useToggleModal(ApplicationModal.CREATE_POOL);
  const isCreatePoolModalOpen = useModalOpen(ApplicationModal.CREATE_POOL);
  const [current, setCurrent] = useState(1);
  const [refresh, setRefresh] = useState<Boolean>(false);
  const { getCollateral } = useLaywayRouterCallback();
  const isExtraLarge = useMediaQuery({ query: "(min-width: 1600px)" });
  const isLarge = useMediaQuery({ query: "(max-width: 1440px)" });
  const isMedium = useMediaQuery({ query: "(max-width: 820px)" });
  const isSmall = useMediaQuery({ query: "(max-width: 576px)" });
  const pageSize = isExtraLarge ? 8 : isMedium ? 4 : 6;
  const [contentFilter, setContentFilter] = useState("Opening");
  const listIconsPagi = [iconPagi1, iconPagi2, iconPagi3];

  const [params, setParams] = useState<IPool>({
    pageIndex: current,
    pageSize: pageSize,
    search: undefined,
    minSellAmount: undefined,
    sellToken: undefined,
    payToken: undefined,
    minPrice: undefined,
    maxPrice: undefined,
    price: undefined,
    isPoolOpen: PoolStatus.OPEN,
    sort: "-createdAt",
  });
  const { data, totalPage, loading } = useSalePool(params, refresh);
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const isConnected = Boolean(LocalhostStorage.get("isConnected"));
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onChange: PaginationProps["onChange"] = (currentPage) => {
    setParams({ ...params, pageIndex: currentPage });
    setCurrent(currentPage);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };
  const handleSearch = debounce((value: string) => {
    setParams({
      ...params,
      search: value,
      pageIndex: 1,
    });
    setCurrent(1);
  }, 500);

  const searchBySellAmount = debounce((value: number) => {
    setParams({
      ...params,
      minSellAmount: value,
      pageIndex: 1,
    });
    setCurrent(1);
  }, 500);

  const searchByPrice = debounce((value: number) => {
    setParams({
      ...params,
      maxPrice: value,
      pageIndex: 1,
    });
    setCurrent(1);
  }, 500);

  const searchBySellToken = (value: string) => {
    setParams({
      ...params,
      sellToken: value,
      pageIndex: 1,
    });
    setCurrent(1);
  };
  const searchByPayToken = (value: string) => {
    setParams({
      ...params,
      payToken: value,
      pageIndex: 1,
    });
    setCurrent(1);
  };

  const onFilterApply = (values: any) => {
    setParams({
      ...params,
      ...values,
    });
  };

  const handleFilterPage: MenuProps["onClick"] = async (e) => {
    try {
      setContentFilter(e.key);
      switch (e.key) {
        case "Opening":
          setParams({ ...params, pageIndex: 1, isPoolOpen: PoolStatus.OPEN });
          setCurrent(1);
          break;
        case "Closed":
          setParams({ ...params, pageIndex: 1, isPoolOpen: PoolStatus.CLOSE });
          setCurrent(1);
          break;
      }
    } catch (e) {
      console.log(e.message);
    }

    return true;
  };

  useEffect(() => {
    setParams({
      ...params,
    });
    LocalhostStorage.set("pageCurrent", "pool");
  }, []);

  useEffect(() => {
    if (!isConnected) {
      navigate(PoolLayaway.HOME);
    }
  }, [isConnected, chainId]);

  const menu = (
    <Menu
      onClick={handleFilterPage}
      items={[
        {
          label: "Opening",
          key: "Opening",
        },
        {
          label: "Closed",
          key: "Closed",
        },
      ]}
    />
  );

  useEffect(() => {
    void (async () => {
      const res = await getCollateral();
      if (res) dispatch(setCollateral(res));
    })();
  }, [account, chainId]);

  return (
    <HomepageTemplate>
      <StyledDrawer
        title="Filter"
        placement="left"
        onClose={onClose}
        visible={visible}
        closable={true}
      >
        <PoolFilterDrawer
          onApply={(values) => {
            onFilterApply(values);
            onClose();
          }}
        />
      </StyledDrawer>
      <CreatePoolModal
        visible={isCreatePoolModalOpen}
        onCancel={toggleCreatePool}
        refresh={handleRefresh}
      />
      <ActionWrapper>
        <FilterLeftStyled>
          <WrapperButton>
            <Dropdown overlay={menu}>
              <FilterButton>
                {contentFilter}

                <Space>
                  <DownOutlined />
                </Space>
              </FilterButton>
            </Dropdown>
          </WrapperButton>
        </FilterLeftStyled>
        {isMedium && (
          <WrapperButton>
            <TextButton onClick={showDrawer}>Filter</TextButton>
          </WrapperButton>
        )}
        <ButtonRightStyled>
          <WrapperButton>
            <TextButton onClick={toggleCreatePool}>CREATE</TextButton>
          </WrapperButton>
        </ButtonRightStyled>
        <VectorCreate src={vector} alt="" preview={false} />
      </ActionWrapper>

      <WapperPool gutter={[12, 12]}>
        {!isMedium && (
          <WrapperCol xs={24} sm={24} md={8} lg={7} xl={7} xxl={6}>
            <PoolFilter
              onSearch={handleSearch}
              searchBySellAmount={searchBySellAmount}
              searchByPrice={searchByPrice}
              searchBySellToken={searchBySellToken}
              searchByPayToken={searchByPayToken}
            />
          </WrapperCol>
        )}

        <Col xs={24} sm={24} md={17} xl={17} xxl={18}>
          <ListPoolWrapper>
            {data?.length ? (
              <GridItems gutter={[12, 12]}>
                {data?.map((item) => (
                  <ColItems
                    key={data.indexOf(item)}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={8}
                    xxl={6}
                  >
                    <PoolItem item={item} refresh={handleRefresh} />
                  </ColItems>
                ))}
              </GridItems>
            ) : loading ? (
              <SpinWrapper justify="center">
                <Spin indicator={antIcon} />
              </SpinWrapper>
            ) : (
              <Row justify={"center"}>
                <EmptyWrapper image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Row>
            )}
          </ListPoolWrapper>
          <Row justify="center" align="middle">
            {totalPage >= pageSize && (
              <StyledPagination
                current={current}
                onChange={onChange}
                total={totalPage}
                pageSize={pageSize}
                itemRender={(_, type, originalElement) => {
                  if (type === "prev") {
                    return <Image src={prvIcon} alt="" preview={false} />;
                  }
                  if (type === "next") {
                    return <Image src={nextIcon} alt="" preview={false} />;
                  }
                  if (type === "jump-prev" || type === "jump-next") {
                    return originalElement;
                  } else
                    return (
                      <Image
                        src={
                          listIconsPagi[
                            Math.floor(Math.random() * listIconsPagi.length)
                          ]
                        }
                        alt=""
                        preview={false}
                      />
                    );
                }}
              />
            )}
            {totalPage >= pageSize && (
              <SubPagination
                current={current}
                onChange={onChange}
                total={totalPage}
                pageSize={pageSize}
                simple
              />
            )}
          </Row>
        </Col>
      </WapperPool>
      <ImgFooter src={bgFooter} alt="" preview={false} />
    </HomepageTemplate>
  );
};
