import { ModalStyled, Wrapper, WrapperImage,WrapperText } from "./styled";
import icSuccess from "assets/icons/ic_success.png"
import iconSuccess from "assets/icons/icon-success.svg"

export const SuccessModal = ({ onCancel, visible }) => {
  return (
    <ModalStyled visible={visible} onCancel={onCancel} footer={false}>
      <Wrapper>
        <WrapperImage src={iconSuccess} alt="success" />
      </Wrapper>
      <Wrapper>
        <WrapperText>Your transaction was successful!</WrapperText>
      </Wrapper>
    </ModalStyled>
  );
};
