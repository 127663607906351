import Button from "antd/lib/button";
import styled from "styled-components/macro";

export const TextButton = styled(Button)<any>`
  font-size: ${(props) => props.about || "14px"};
  font-weight: 600;
  letter-spacing: 0.07rem;
  background-color: ${(props) => props.color || "#9600B0"};
  color: ${(props) => props.colorText || "white"};
  width: 120px;
  border-radius: 60px;
  height: auto;
  border: none;
  padding: 6px 20px;
  :disabled {
    background-image: linear-gradient(#242526, #000) !important;
    color: ${(props) => props.colorText || "white"};
  }
  :hover {
    background-color: ${(props) => props.color || "#9600B0"};
    color: ${(props) => props.colorText || "white"};
  }
  :focus {
    background-color: ${(props) => props.color || "#9600B0"};
    color: ${(props) => props.colorText || "white"} !important;
  }
  @media (max-width: 576px){
    width: 105px;
  }
`;

export const ClaimButton = styled(Button)`
  background: transparent;
  border: #cff54d 1px solid;
  color: #000;
  font-size: 15px;
  font-family: "Bicyclette Bold";
  border-radius: 53px;
  :hover, :focus{
    background: #eaff75;
    border: #fff 1px solid;
    color: #000;
  }
`;
