import { createSlice } from "@reduxjs/toolkit";

export enum ApplicationModal {
  CREATE_ORDER,
  CREATE_POOL
}

type PopupList = Array<{
  key: string;
  show: boolean;
  removeAfterMs: number | null;
}>;

export interface ApplicationState {
  readonly openModal: ApplicationModal | null;
  readonly popupList: PopupList;
}

const initialState: ApplicationState = {
  openModal: null,
  popupList: [],
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
  },
});

export const { setOpenModal } = applicationSlice.actions;
export default applicationSlice.reducer;
