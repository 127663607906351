/* eslint-disable react-hooks/exhaustive-deps */
import { LocalhostStorage } from "utils/sessionStorage";
// import { resetPassesState } from "redux/slices/passes";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { logout } from "redux/slices/user";
import { PoolLayaway } from "constants/routers";

export const useDisconnect = () => {
  const { deactivate } = useWeb3React();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const disconnect = async () => {
    try {
      //   dispatch(resetPassesState());
      deactivate();
      LocalhostStorage.clear();
      dispatch(logout());
      navigate(PoolLayaway.HOME);
    } catch (error) {}
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // return useCallback(disconnect, [dispatch, navigate]);
  return useCallback(disconnect, [dispatch, navigate, deactivate]);
};
