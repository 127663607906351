import styled from "styled-components";
import { Col, Empty, Pagination, Row, Image } from "antd";
import { RowBetween } from "components/Row";
import { TextButton } from "components/TextButton";
import bgPagi from "assets/vectors/vector-pagination.svg";
import prvIcon from "assets/icons/prev-icon.svg";
import nextIcon from "assets/icons/next-icon.svg";

export const WapperPool = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  .ant-empty-description {
    color: #fff;
  }
  .ant-pagination {
    margin-top: 30px;
    .ant-pagination-next .ant-pagination-item-link {
      .anticon-right {
        vertical-align: middle;
      }
    }
    .ant-pagination-prev .ant-pagination-item-link {
      .anticon-left {
        vertical-align: middle;
      }
    }
  }
`;

export const ActionWrapper = styled(RowBetween)`
  top: 70px;
  background-color: transparent;
  padding-bottom: 20px;
  z-index: 2;
  @media (max-width: 820px) {
    justify-content: center;
    padding: 20px;
  }
  .ant-image {
    position: static;
  }
`;

export const WrapperCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const GridItems = styled(Row)`
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const ColItems = styled(Col)`
  padding: 0px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: 1200px) {
    justify-content: end;
  }
  @media (max-width: 820px) and (min-width: 769px) {
    margin-right: -12px;
  }
  @media (max-width: 1024px) and (min-width: 821px) {
    margin: 0 -15px;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    padding-left: 10px;
  }
  /* @media (max-width: 820px) {
    padding-left: 4px;
  } */
  @media (max-width: 820px) {
    justify-content: center;
    margin: 8px;
    padding-left: 0;
  }
`;
export const SpinWrapper = styled(Row)``;

export const EmptyWrapper = styled(Empty)`
  color: #fff;
`;

export const StyledPagination = styled(Pagination)`
  text-align: center;
  margin-top: 50px;
  /* margin-bottom: 50px; */
  .ant-pagination-next .ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    .anticon-right {
      vertical-align: middle;
      svg {
        fill: rgba(207, 245, 77, 0.4);
      }
    }
  }
  .ant-pagination-prev .ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    .anticon-left {
      vertical-align: middle;
      svg {
        fill: rgba(207, 245, 77, 0.4);
      }
    }
  }

  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #fff;
  }

  .ant-pagination-item {
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    a {
      display: none;
    }
    .ant-image-img {
      opacity: 0.3;
    }
  }

  /* .ant-pagination-prev {
    background: url(${prvIcon});
  } */
  /* .ant-pagination-next {
    background: url(${nextIcon});
  } */
  .ant-pagination-prev,
  .ant-pagination-next {
    /* display: none; */
    background-repeat: no-repeat;
    background-position: center;
    .ant-image-img {
      /* display: none; */
    }
  }

  .ant-pagination-disabled {
    opacity: 0.5;
  }
  .ant-pagination-options-quick-jumper {
    color: #cff54d;
    input {
      background-color: transparent;
      border: #cff54d 1px solid;
      color: #fff;
    }
  }
  .ant-pagination-options-size-changer {
    display: none;
  }
  .ant-pagination-item-active {
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    .ant-image-img {
      opacity: 1;
    }
    a {
      /* display: none; */
    }
    border: none;
  }
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: #cff54d;
  }
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #cff54d;
  }
  .ant-pagination-jump-next .ant-pagination-item-link,
  .ant-pagination-jump-prev .ant-pagination-item-link {
    display: block !important;
  }
`;

export const SubPagination = styled(Pagination)`
  .ant-pagination-simple-pager {
    font-size: 12px;
    color: #fff;
    input {
      border: #cff54d 1px solid;
      background: transparent;
      &:hover,
      &:focus-visible {
        border: #cff54d 1px solid;
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: none;
  }

  text-align: center;
  margin-top: 50px;
  margin-left: 8px;
`;

export const FilterButton = styled(TextButton)`
  svg {
    margin-left: 4px;
    margin-bottom: 4px;
  }
`;

export const RowFooter = styled(Row)`
  position: absolute;
  bottom: 0;
  width: 90%;
`;

export const FilterLeftStyled = styled.div`
  position: absolute;
  bottom: 10px;
  left: 80px;
  z-index: 10;
  @media (max-width: 820px) {
    bottom: 10px;
    left: 49px;
  }
  @media (max-width: 576px) {
    position: relative;
    bottom: unset;
    left: unset;
  }
`;

export const ButtonRightStyled = styled.div`
  position: absolute;
  bottom: 10px;
  right: 80px;
  z-index: 10;
  @media (max-width: 820px) {
    bottom: 10px;
    right: 49px;
  }
  @media (max-width: 576px) {
    position: relative;
    bottom: unset;
    right: unset;
  }
`;

export const VectorCreate = styled(Image)`
  position: absolute;
  bottom: 3px;
  right: 53px;
  z-index: 2;
  width: 240px;
  @media (max-width: 820px) {
    bottom: 10px;
    right: 40px;
    width: 211px;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

export const ImgFooter = styled(Image)`
  position: absolute;
  bottom: -60px;
  left: -132px;
  width: 500px;
  z-index: -1;
`;

export const ListPoolWrapper = styled.div`
  min-height: 688px;
  @media (max-width: 1024px) and (min-width: 1000px) {
    min-height: 1134px;
  }
`;
