import Api from "../index";

export enum PoolStatus {
  OPEN = 1,
  CLOSE = 2,
}

export interface IPool {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  minSellAmount?: number;
  sellToken?: string;
  payToken?: string;
  minPrice?: number;
  maxPrice?: number;
  price?: number;
  isPoolOpen?: PoolStatus;
  sort?: string;
}

export interface IOrder {
  pageIndex?: number;
  pageSize?: number;
  orderId?: string;
  purchaseAmountFormat?: number;
  sellToken?: string;
  priceFormat?: number;
  payToken?: string;
  termDaysLimit?: number;
  minPrice?: number;
  expireDate?: number;
  orderStatus?: number;
  payingSecondTimeFormat?: number;
  isExpire?: number;
  userAddress?: string;
  sort?: string;
}

export interface IToken {
  pageIndex?: number;
  pageSize?: number;
  tokenAddress?: string;
  symbol?: string;
  name?: string;
  search?: string;
  img?: string;
}

export const getSalePool = async (data: IPool) => {
  try {
    const res = await Api.request({
      method: "get",
      data: {
        params: data,
      },
      endpoint: "/sale-pool",
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getToken = async (data: IToken) => {
  try {
    const res = await Api.request({
      method: "get",
      data: {
        params: data,
      },
      endpoint: "/token",
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const postTokenWithAddress = async (data: IToken) => {
  try {
    const res = await Api.request({
      method: "post",
      data,
      endpoint: "/token/create-with-address",
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getOrder = async (data: IOrder) => {
  try {
    const res = await Api.request({
      method: "get",
      data: {
        params: data,
      },
      endpoint: "/order",
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};
