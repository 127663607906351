import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useCallback } from "react";
import { useSellTokenContract } from "./useSellTokenContract";
import { MAX_UINT_256 } from "constants/value";
import { BigNumber } from "ethers";

const useSellTokenCallback = (token: string) => {
  const { account } = useActiveWeb3React();
  const sellToken = useSellTokenContract(token);

  const allowance = useCallback(
    async (poolAddress: string) => {
      try {
        if (sellToken && account) {
          const tx = await sellToken.allowance(account, poolAddress);
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, token]
  );
  const symbol = useCallback(async () => {
    try {
      if (sellToken && account) {
        const tx = await sellToken.symbol();
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, token]);

  const balanceOf = useCallback(
    async (account) => {
      try {
        if (sellToken && account) {
          const tx = await sellToken.balanceOf(account);
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, token]
  );

  const approve = useCallback(
    async (poolAddress: string) => {
      try {
        if (sellToken && account) {
          const gasLimit = await sellToken.estimateGas.approve(
            poolAddress,
            MAX_UINT_256
          );
          const tx = await sellToken.approve(poolAddress, MAX_UINT_256, {
            gasLimit: BigNumber.from(gasLimit).mul(2),
          });
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, token]
  );

  return { allowance, symbol, balanceOf, approve };
};

export default useSellTokenCallback;
