import AppColumn from "components/Column";
import styled from "styled-components";
import { Checkbox, Row } from "antd";
import { Image } from "antd";

export const LargeTitleLabel = styled.div`
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
`;

export const MediumTitleLabel = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-family: "BicycletteRe";
  line-height: 30px;
  margin-right: 20px;
  margin-top: 25px;
  margin-bottom: 6px;
  padding-left: 8px;
`;

export const FilterWrapper = styled(AppColumn)`
  background-color: transparent;
  z-index: 1;
  height: 100%;
  padding: 17px 20px;
  border-radius: 10px;
  color: #cff54d;
  width: 100%;
  border: #cff54d 3px solid;
  @media (max-width: 1024px) {
    margin-right: -24px;
  }
  @media (max-width: 820px) {
    margin-right: -12px;
  }
  @media (max-width: 768px) {
    width: auto;
    background-color: #000;
    height: auto;
    margin-bottom: 50px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  & > *:not(:first-child) {
    margin-top: 10px;
  }
`;

export const ColumnCheckbox = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    display: flex;
    margin-right: 0;
  }
  .ant-checkbox + span {
    font-weight: 600;
    font-size: 15px;
  }
`;

export const IconToken = styled(Image)`
  width: 21px;
  height: 20px;
  margin-right: 8px;
`;

export const TokenRow = styled(Row)`
  width: 100px;
  .ant-image {
    position: relative;
    display: flex;
    align-items: center;
  }
  font-size: 16px;
`;
