import { Radio, Row } from "antd";
import { RowCenter } from "components/Row";
import styled from "styled-components";

export const WrapperButton = styled(Radio.Group)`
  background: #c4c4c4 !important;
`;

export const LogoWrapper = styled(Row)`
  img {
    width: 130px;
  }
  @media (max-width: 576px) {
    img {
      width: 100px;
    }
  }
`;

export const LogoTitle = styled.div`
  margin-left: 8px;
  color: white;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
`;

export const ButtonTab = styled(Radio.Button)`
  background: #c4c4c4;
  color: #000;
  .ant-radio-button-checked {
    background: #e4e4e4 !important;
    color: #000;
  }
  span {
    color: #000;
  }
`;

export const ConnectWalletBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 36px;
  background: #000;
  border-radius: 20px;
  cursor: pointer;
  // border: 1px solid #000;
`;

export const TextConnectBtn = styled.p`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #cff54d;
`;

export const ListWebsitWrapper = styled.div`
  background-color: #000;
  color: #cff54d;
  position: absolute;
  padding: 10px;
  width: 100%;
  top: 40px;
  border-radius: 10px;
  a {
    display: block;
    padding: 5px 0;
    color: #cff54d;
    font-size: 16px;
  }
`;
