import { Modal, Button, Row, Col, Image } from "antd";
import styled from "styled-components";
import { Dropdown } from "antd";
import { RowCenter } from "components/Row";
export const ConnectWalletModalStyled = styled(Modal)`
  width: 100vw;
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-body {
      text-align: center;
      width: 80%;
      margin: 0 10%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-modal-close-x,
    .ant-typography {
      color: ${(p) => p.theme.colors.textColor};
    }
    .ant-typography {
      margin: 0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      text-transform: capitalize;
      color: #000;
    }
  }
`;

export const ConnectButton = styled(Button)`
  width: 100%;
  min-height: 3em;
  height: auto;
  padding: 0.5em;
  padding-left: 20%;
  margin-top: 24px;
  margin: 1em;
  box-sizing: border-box;
  border-radius: 12px;
  background: #fff;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: start;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }
  span {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    span {
      font-size: 16px;
    }
  }
  @media (max-width: 375px) {
    padding-left: 15%;
  }
  @media (max-width: 320px) {
    padding-left: 10%;
  }
`;

export const ConnectTitle = styled.h6`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000;
`;

export const ConnectIconStyled = styled.span`
  color: #fff;
  font-size: 35px;
`;

export const ConnectButtonStyled = styled.div`
  position: relative;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #ffffff;
  border-radius: 12px;
  cursor: pointer;
`;

export const WalletIcon = styled.div`
  margin-right: 8px;
  svg {
    width: 17px;
    height: 13.4px;
  }
`;

export const ConnectWalletBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  background: #e4e4e4;
  border-radius: 20px;
  cursor: pointer;
  padding: 14px;
  margin-left: 12px;
`;

export const ConnectWalletBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border-radius: 30px;
  padding: 10px 42px;
  margin-top: 100px;
  cursor: pointer;
  @media (max-width: 576px) {
    padding: 10px 22px;
  }
`;

export const TextConnectBtnBig = styled.p`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  color: #fff;

  img {
    margin-right: 10px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const TextConnectBtn = styled.p`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  color: #000;
`;

export const UserMenuButton = styled.button<any>`
  padding: 0 !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #fff;
  border: none;
  background: transparent;
  &:hover,
  &:focus,
  &:active {
    border: none;
    background: transparent;
  }
  @media (max-width: 540px) and (min-width: 480px) {
    margin-left: 50px;
  }
`;

export const WrapperAddress = styled.div`
  margin-left: 24px;
  padding: 5px 20px;
  background: #000;
  border-radius: 30px;

  span {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    color: #fff;
    mix-blend-mode: normal;
    margin-right: 12px;
    text-transform: lowercase;
    letter-spacing: 0.08rem;
  }
  @media (max-width: 360px) {
    margin-left: 18px;
  }
  @media (max-width: 320px) {
    padding: 5px 12px;
    span {
      margin-right: -1px;
    }
  }
`;

export const WrapperNetwork = styled(RowCenter)`
  padding: 6px 10px;
  background: #000;
  margin-top: 10px;
  border-radius: 30px;
  font-size: 12px;
  span {
    margin: 0;
    font-family: "Inter";
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #fff;
    mix-blend-mode: normal;
  }
`;

export const EtherImg = styled.img`
  padding: 0;
  height: 25px;
  margin-right: 10px;
`;

export const DropdownUser = styled(Dropdown)``;

export const Navigation = styled.p`
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const HeaderWrapper = styled(Row)<{ active?: boolean }>`
  margin-left: 80px;
  margin-right: 80px;
  padding: 30px 0px;
  /* cursor: pointer; */
  min-height: 162px;
  justify-content: center;
  @media (max-width: 1180px) {
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
  }
  .ant-menu-item:active {
    background: transparent;
  }
  @media (min-width: 1601px) {
    max-width: 1380px;
    margin: auto;
    width: 90%;
  }
`;

export const WrapperNavigation = styled(Col)`
  margin-top: 20px;
`;
