import styled from "styled-components";
import { Radio } from "antd";
import { TextButton } from "components/TextButton";
import vector from "assets/vectors/vector-nav.svg"
export const ButtonWrapper = styled(Radio.Group)`
  background: transparent;
  /* padding: 4px 5px; */
  border-radius: 10px;
  .ant-radio-button-wrapper {
    background: transparent;
    width: 128px;
    height: 54px;
    border: 0px;
    padding: 5px;
    padding-left: 36px;
    padding-top: 13px;
    margin-right: 4px;
    text-transform: uppercase;
    span {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
    @media (max-width: 576px) {
      width: 82px;
      padding: 4px 16px;
      height: 36px;
      span {
      font-size: 16px;
      }
    }
  }
  .ant-radio-button-wrapper-checked {
    .ant-radio-button-checked {
      border-radius: 10px;
      background: url(${vector});
      background-size: contain;
      background-repeat: no-repeat;
    }
    span {
      color: #CFF54D !important;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: none;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background: none;
  }

  .ant-radio-button-wrapper:not(:first-child):before {
    background: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: none;
  }
`;

export const RadioButtonWrapper = styled(Radio.Button)`
  background: none;
`;

export const ClaimButton = styled(TextButton)`
 span {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
`;

export const NavWrapper = styled.div`
  background-color: rgba(0,0,0,0.8);
  border-radius: 20px;
`;