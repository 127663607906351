import logoMetamask from "assets/logo-metamask.png";
import {
  ConnectButton,
  ConnectTitle,
  ConnectWalletModalStyled,
  ConnectWalletBtn,
  TextConnectBtn,
  UserMenuButton,
  WrapperAddress,
  DropdownUser,
} from "styles/Common/ConnectWallet.styled";
import { isEmpty } from "lodash";
import { Image } from "antd";
import { injected, WalletConnectInstance } from "utils/wallets/connector";
import { setupNetwork } from "utils/wallets/setupNetwork";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { UserMenu } from "components/usersMenu/UserMenu";
import { Web3Provider } from "@ethersproject/providers";
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
} from "@web3-react/walletconnect-connector";
import signMessage from "services/Contracts/signMessage";
import { LocalhostStorage } from "utils/sessionStorage";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { handleClick } from "redux/slices/connect";
import { loginWallet } from "redux/slices/user";
import { useNavigate } from "react-router-dom";
import { PoolLayaway } from "constants/routers";
import { setSignar } from "redux/slices/signar";
import userIcon from "assets/icons/icon-user.svg"
interface IProps {
  isMobile?: boolean;
}

const ConnectWallet = (props: IProps) => {
  const { account, connector, activate, deactivate } =
    useWeb3React<Web3Provider>();
  const [activatingConnector, setActivatingConnector] = useState<
    InjectedConnector | WalletConnectConnector
  >();
  const [visible, setVisible] = useState<boolean>(false);
  const CHAIN_ID = LocalhostStorage.get("chainId");

  const { isVisible } = useSelector((state: RootStateOrAny) => state.connect);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleVisible = () => {
    dispatch(handleClick());
  };

  const handleShow = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (!CHAIN_ID)
      LocalhostStorage.set("chainId", +process.env.REACT_APP_CHAIN_ID);
  }, []);
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const disconnect = async () => {
    deactivate();
    const chainId = LocalhostStorage.get("chainId");
    LocalhostStorage.clear();
    LocalhostStorage.set("chainId", chainId);
  };

  useEffect(() => {
    void (() => {
      const isConnected = Boolean(LocalhostStorage.get("isConnected"));
      const typeOfConnector = LocalhostStorage.get("typeOfConnector");

      if (isConnected && typeOfConnector === "Metamask") {
        void activate(injected(CHAIN_ID));
        dispatch(loginWallet(LocalhostStorage.get("account")));
      }

      if (isConnected && typeOfConnector === "Walletconnect") {
        void activate(WalletConnectInstance);
        dispatch(loginWallet(LocalhostStorage.get("account")));
      }
    })();
  }, []);

  useEffect(() => {
    const isConnected = Boolean(LocalhostStorage.get("isConnected"));
    const walletAddress = LocalhostStorage.get("account");
    dispatch(setSignar(LocalhostStorage.get("isConnected")));

    if (!isEmpty(account)) {
      if (
        isConnected &&
        account?.toUpperCase() !== walletAddress?.toUpperCase()
      ) {
        disconnect();
      }
    }
    // if ( !isConnected) {
    //   navigate(PoolLayaway.HOME);
    // }
  }, [account]);

  const handleConnect = async (
    otherConnector: InjectedConnector | WalletConnectConnector
  ) => {
    try {
      setActivatingConnector(otherConnector);
      await activate(
        otherConnector,
        async (err: Error) => {
          if (err instanceof UnsupportedChainIdError) {
            const provider = await otherConnector.getProvider();

            const hasSetup = await setupNetwork(provider);

            if (hasSetup) {
              activate(otherConnector);
            }
          } else {
            if (err instanceof NoEthereumProviderError) {
              // toast.error('Provider Error: No provider was found');
            } else if (
              err instanceof UserRejectedRequestErrorInjected ||
              err instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (otherConnector instanceof WalletConnectConnector) {
                const walletConnector =
                  otherConnector as WalletConnectConnector;
                walletConnector.walletConnectProvider = null;
              }
              // toast.error('Authorization Error: Please authorize to access your account');
            } else {
              // toast.error(err.name + ':' + err.message);
            }
          }
        },
        false
      );

      const addressWallet = await otherConnector.getAccount();

      if (!addressWallet) return;
      const walletProvider = await otherConnector.getProvider();
      const dataSignature = await signMessage(addressWallet, walletProvider);
      if (dataSignature?.success) {
        dispatch(setSignar(dataSignature?.success));

        let message = LocalhostStorage.get("message");
        let signature = LocalhostStorage.get("signature");
        let walletAddress = addressWallet;

        message = dataSignature?.data?.message;
        signature = dataSignature?.data?.signature;
        walletAddress = dataSignature?.data?.account;

        LocalhostStorage.set("message", message);
        LocalhostStorage.set("signature", signature);
        LocalhostStorage.set("account", walletAddress);
        LocalhostStorage.set("isConnected", "true");
        dispatch(loginWallet(walletAddress));

        if (otherConnector instanceof InjectedConnector) {
          LocalhostStorage.set("typeOfConnector", "Metamask");
        }
        if (otherConnector instanceof WalletConnectConnector) {
          LocalhostStorage.set("typeOfConnector", "WalletConnect");
        }
      }
    } catch (e) {
      deactivate();
    }
  };

  const checkConnected = () => {
    const isConnected = Boolean(LocalhostStorage.get("isConnected"));
    return isConnected;
  };

  return (
    <>
      <ConnectWalletModalStyled
        centered
        visible={isVisible}
        onOk={handleVisible}
        onCancel={handleVisible}
        footer={false}
      >
        <ConnectTitle>Connect wallet to play now</ConnectTitle>
        <ConnectButton
          onClick={() => {
            handleConnect(injected(CHAIN_ID));
            handleVisible();
          }}
        >
          <img src={logoMetamask} alt="Metamask" />
          MetaMask
        </ConnectButton>
      </ConnectWalletModalStyled>

      {(!account || !checkConnected()) && (
        <ConnectWalletBtn onClick={handleVisible}>
          <TextConnectBtn>Connect Wallet</TextConnectBtn>
        </ConnectWalletBtn>
      )}
      {account && checkConnected() && (
        <>
          <DropdownUser
            visible={visible}
            onVisibleChange={handleShow}
            trigger={["click"]}
            overlay={
              <UserMenu
                setVisible={setVisible}
                visible={visible}
                account={account}
              />
            }
          >
            <UserMenuButton>
              <WrapperAddress>
                <span>{`${account.substring(0, 6)}...${account.substring(
                  38
                )}`}</span>
                <Image src={userIcon} alt="" preview={false}/>
              </WrapperAddress>
            </UserMenuButton>
          </DropdownUser>
        </>
      )}
    </>
  );
};

export default ConnectWallet;
