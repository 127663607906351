import { useMemo } from 'react';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';

export default function useContract<T extends Contract = Contract>(
  address: string,
  ABI: any
): T | null {
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  const dispatch = useDispatch();

  return useMemo(() => {
    if (!address || !ABI || !library) {
      return null;
    }

    try {
      return new Contract(address, ABI, account ? library?.getSigner(account) : library);
    } catch (error) {
      console.error('useContract', 'Failed To Get Contract', error);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, ABI, library, account]) as T;
}
