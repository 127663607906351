import { combineReducers } from "redux";

import counter from "redux/slices/counter";
import connect from "redux/slices/connect";
import user from "redux/slices/user";
import applicationModal from "redux/slices/application";
import page from "redux/slices/page";
import collateral from "./slices/collateral";
import signar from "./slices/signar";
import refreshToken from "./slices/refreshToken";
const rootReducer = combineReducers({
  signar,
  collateral,
  counter,
  connect,
  user,
  page,
  applicationModal,
  refreshToken,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
