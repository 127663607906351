import { Button, Modal, Row, Image } from "antd";
import styled from "styled-components";

export const ClaimModalWrapper = styled(Modal)`
  /* max-width: 450px; */
  width: 100%;
  top: calc(calc(100% - 400px) / 2);
  .ant-modal-content {
    background: #000;
    border-radius: 10px;
    border: #cff54d 1px solid;
    .ant-modal-body {
    }
  }
`;
export const LabelWrapper = styled.label`
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  @media(min-width: 1400px){
    font-size: 14px;
  }
  @media(min-width: 1600px){
    font-size: 16px;
  }
`;
export const LabelValueWrapper = styled.label`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.color || "#fff"};
  @media(min-width: 1400px){
    font-size: 14px;
  }
  @media(min-width: 1600px){
    font-size: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 56px;
`;

export const WrapperContent = styled(Row)`
  margin: 12px 24px 0;
`;

export const TitleStyled = styled.p`
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 13px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background-color: transparent;
  color: black;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  ::placeholder {
    color: #749cff;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }
`;
export const ClaimButton = styled(Button)<any>`
  border-radius: 10px;
  /* width: 100%; */
  border: none;
  font-weight: 700;
  height: 40px;
  display: flex;
  margin-top: -23px;
  background: transparent !important;
  color: #cff54d !important;
  justify-content: center;
  align-items: center;
  :disabled,
  :disabled:hover {
    background-color: transparent;
    color: #ccc !important;
    background-image: none !important;
  }
  :hover,
  :focus {
    background: transparent;
    color: #cff54d;
  }
  @media(min-width: 1400px){
    font-size: 16px;
  }
  @media(min-width: 1600px){
    font-size: 17px;
  }
`;

export const HeadlineStyled = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: white;
`;

export const LineTitle = styled(Image)`
  width: 56px;
  display: block;
  position: absolute;
  bottom: 6px;
  right: -6px;
  height: 20px;
  @media (min-width: 1400px){
    width: 68px;
  }
  @media (min-width: 1600px){
    width: 78px;
    bottom: 10px;
  }
`;
