import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface RedirectProps {
	to: string;
}

export const Redirect = ({ to }: RedirectProps) => {
	let navigate = useNavigate();
	useEffect(() => {
		navigate(to);
	});
	return null;
};
