import { ColumnCenter } from "components/Column";
import styled from "styled-components";
import { Row, Segmented, Image } from "antd";
import background from "assets/images/homepage.png"

export const WrapperHome = styled(ColumnCenter)`
  position: absolute;
  bottom: 2px;
  /* background-color: #222429; */
  /* padding: 12px 0px; */
  z-index: 2;
`;
export const WrapperSegment = styled.div`
  margin: 0px 100px;
  position: relative;
`;

export const MainContent = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  flex-grow: 1;
  @media (max-width: 1600px) {
    margin: 0px 100px;
  }

  @media (max-width: 1024px) and (min-width: 821px){
    margin: 0px;
    padding: 0 80px;
  }
  @media(max-width: 820px){
    padding:0;
    margin: 0px 20px;
  }
  @media(min-width: 1601px){
    max-width: 1380px;
    margin: auto;
    width: 90%;
  }
`;

export const HomeSegment = styled(Segmented)`
  background-color: #5180f4;
  border-radius: 10px;
  padding: 5px;

  :hover {
    background-color: #5180f4 !important;
  }
  .ant-segmented-item {
    border-radius: 10px !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: white;
    padding: 8px 30px;
  }

  .ant-segmented-item-selected {
    color: #5180f4;
    background-color: white;
  }
`;

export const GridItems = styled(Row)`
  margin: 0 !important;
  width: 100%;
`;

export const BackgroundHomepage = styled.div`
  /* background: url(${background}); */
  height: 100vh;
  background-size: 100%;
`

export const Background = styled(Image)`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
`;

export const MainBackground = styled(Image)`
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -10.1rem;
  z-index: -1;
  @media(max-width: 1024px){
    top: -15.1rem;
  }
  @media(max-width: 1024px) and (max-height: 768px){
    top: -10.5rem;
  }
  @media(max-width: 820px){
    width: fit-content;
    top: -13.1rem;
  }
  @media(max-width: 576px){
    width: auto;
    top: -10.1rem;
  }
`;
