import styled from "styled-components";
import { Col, Row, Image } from "antd";
import { ColumnCenter } from "components/Column";
export const LoginContainer = styled(ColumnCenter)`
  width: 50%;
  height: 56vh;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  @media (max-width: 576px) {
    width: 70%;
  }
`;

export const HeadlineStyled = styled.p`
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  color: #fff;
`;

export const ButtonWraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RowStyled = styled(Row)``;

export const ColLeft = styled(Col)`
  width: 70% !important;
  padding-right: 30px !important;
  .title {
    text-align: right;
  }
  .paragraph {
    text-align: right;
    width: 50%;
    float: right;
    color: #8d8d8d;
    font-size: 20px;
  }
`;

export const ColRight = styled(Col)``;

export const WrapperSocial = styled.div`
  background: #f3f3f3;
  width: 70%;
  height: 250px;
  border-radius: 15px;
`;

export const ConnectText = styled.p`
  font-size: 25px;
  padding-top: 20px;
  text-align: center;
`;

export const TwitterImg = styled.img`
  height: 40px;
  margin: 10px;
`;

export const ImgWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

export const ImgLogo = styled(Image)`
  width: 300px;
  @media (max-width: 820px) {
    width: 200px;
  }
  @media (max-width: 576px) {
    width: 150px;
  }
`;

export const VectorHomepage = styled(Image)`
  position: absolute;
  z-index: -1;
  width: 550px;
  top: -64px;
  left: -146px;
  @media (max-width: 576px) {
    width: 360px;
    top: -25px;
    left: -78px;
  }
`;
export const Background = styled(Image)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
  left: -100px;
  @media(min-width: 1600px){
    left: calc(calc(1380px - 100vw) / 2);
  }
  @media (max-width: 1024px) and (min-width: 821px){
    left: -80px;
  }
  @media(max-width: 820px){
    left: -20px;
  }

`;
