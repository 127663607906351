import { Col, notification } from "antd";
import OrderButton from "components/button/OrderButton";
import { ContractType } from "constants/enum/ContractType";
import { EventType } from "constants/enum/EventType";
import useLaywayRouterCallback from "hooks/layaway-router/useLayawayRouterCallback";
import { useState } from "react";
import { postContractAction } from "services/api/contract_action";
import {
  CancelContent,
  CancelModalWrapper,
  TitleStyled,
  Wrapper,
  WrapperButton,
  LineTitle,
  WrapperButtonCancel,
  ButtonBackground,
  ButtonStyled,
  UnderLine,
} from "./styled";
import vector from "assets/vectors/underline-cancel.svg";
import bgButton from "assets/vectors/bg-confirm-button.svg";
import underline from "assets/vectors/underline-cancel-button.svg";
import { toast } from "react-toastify";
export const CancelModal = ({
  id,
  onCancel,
  visible,
  success,
  failed,
  refresh,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { cancelOrder } = useLaywayRouterCallback();

  const handleCancelOrder = async () => {
    try {
      setLoading(true);
      const res = await cancelOrder(id);
      refresh();
      if (!!res?.transactionHash) {
        const rs = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Router,
          contractAddress: process.env.REACT_APP_LAYAWAY_ROUTER,
          eventName: EventType.CancelledOrder,
        });
        if (!!rs) {
          success();
          refresh();
        } else {
          failed();
        }
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      toast.error(e?.reason || e?.message);
    }
    setLoading(false);
  };

  return (
    <CancelModalWrapper visible={visible} onCancel={onCancel} footer={false}>
      <Wrapper>
        <TitleStyled>Cancel order</TitleStyled>
        <LineTitle src={vector} alt="" preview={false} />
      </Wrapper>
      <Wrapper>
        <CancelContent>id {id}</CancelContent>
      </Wrapper>
      <WrapperButton justify="space-between" gutter={[12, 12]}>
        <Col xs={12}>
          <WrapperButtonCancel>
            <ButtonBackground src={bgButton} alt="" preview={false} />
            <ButtonStyled
              action="confirm"
              onClick={handleCancelOrder}
              loading={loading}
              disabled={false}
            >
              Confirm
            </ButtonStyled>
          </WrapperButtonCancel>
        </Col>
        <Col xs={12}>
          <WrapperButtonCancel>
            <UnderLine src={underline} alt="" preview={false} />
            <ButtonStyled
              action="cancel"
              onClick={onCancel}
              loading={false}
              disabled={loading}
              color="#9600B0"
              martop="-14px"
            >
              Cancel
            </ButtonStyled>
          </WrapperButtonCancel>
        </Col>
      </WrapperButton>
    </CancelModalWrapper>
  );
};
