import { useEffect, useState } from "react";
import { getSalePool, IPool } from "services/api";

export default function useSalePool(params: IPool, refresh: Boolean) {
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res: any = await getSalePool(params);
      if (res?.success) {
        setData(res?.payload?.data);
        setTotalPage(res?.payload?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { data, totalPage, loading };
}
