export const roundNumber = (num, n) => {
  let base = 10 ** n;
  let result = Math.round(num * base) / base;
  return result;
};

export const convertBigNumber = (num, n) => {
  let base = 10 ** n;
  return num / base;
}

export const roundDown = (number, decimals) => {
  decimals = decimals || 0;
  return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
}