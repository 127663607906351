import { Form, Select, Image, Row } from "antd";
import { StyledForm } from "components/StyledForm";
import { SelectStyled } from "components/StyledSelect";
import { ContractType } from "constants/enum/ContractType";
import { EventType } from "constants/enum/EventType";
import useLaywayRouterCallback from "hooks/layaway-router/useLayawayRouterCallback";
import { usePayTokenCallback } from "hooks/pay-token/usePayTokenCallback";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useEffect, useState } from "react";
import { postContractAction } from "services/api/contract_action";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { formatNumberThousand, blockInvalidChar } from "utils/formatNumber";
import { FailedModal } from "../FailedModal";
import { SuccessModal } from "../SuccessModal";
import {
  CreateOrderModalWrapper,
  Wrapper,
  TitleStyled,
  WrapperContent,
  BottomButton,
  FormItemInput,
  FormItemSelect,
  FormItemButton,
  InputField,
  InputWrapper,
  TextWrapper,
  LabelWrapper,
  SelectIcon,
  ButtonBackground,
} from "./styled";
import { notification } from "antd";
import { NotificationEnum } from "constants/enum/Notifications";
import { ethers } from "ethers";
import { SECONDS_PER_DAY } from "constants/value";
import { roundNumber, convertBigNumber } from "utils/roundNumber";
import iconSelect from "assets/icons/select-icon.svg";
import vectorButton from "assets/vectors/vector-order-button.svg";
import { toast } from "react-toastify";
const ETH_ADDRESS = "0x0000000000000000000000000000000000000000";
const ROUND_NUMBER = 10000;
const ROUND_AFTER_COMMA = 4;
export const CreateOrderModal = ({
  poolAdress,
  sellToken,
  payToken,
  price,
  availableSellAmount,
  open,
  onCancel,
  refresh,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { account, chainId } = useActiveWeb3React();
  const { createOrder, createOrderETH, getFirstPayAmount } =
    useLaywayRouterCallback();
  const { allowance, approve, balanceOf } = usePayTokenCallback(
    payToken?.tokenAddress
  );
  const { collateral } = useSelector((state: RootState) => state.collateral);
  const [loading, setLoading] = useState<boolean>(false);
  const [allowanceToken, setAllowanceToken] = useState<Number>(undefined);
  const [purchaseAmountInput, setPurchaseAmountInput] = useState(undefined);
  const [firstPay, setFirstPay] = useState<Number>(undefined);
  const [success, setShowSuccess] = useState(false);
  const [failed, setShowFailed] = useState(false);
  const [refreshAllowance, setRefreshAllowance] = useState<boolean>(false);
  const [firstPayAmount, setFirstPayAmount] = useState<string>("0");
  const [available, setAvalable] = useState(0);
  const [availableNumber, setAvailableNumber] = useState(undefined);
  useEffect(() => {
    void (async () => {
      const res = await getFirstPayAmount(
        ethers.utils.parseUnits(
          purchaseAmountInput || "0",
          parseFloat(sellToken?.decimal)
        ),
        price,
        sellToken?.tokenAddress,
        payToken?.tokenAddress
      );
      if (res) setFirstPayAmount(res);
    })();
  }, [purchaseAmountInput]);

  useEffect(() => {
    void (async () => {
      if (open) {
        const resBalanceOf = await balanceOf(account);
        if (resBalanceOf)
          setAvalable(Number(Number(resBalanceOf / 10 ** payToken.decimal)));
      }
    })();
  }, [account, chainId, refreshAllowance, poolAdress, open]);
  const onCloseModal = () => {
    setShowSuccess(false);
    setShowFailed(false);
  };

  const showSuccess = () => {
    setShowSuccess(true);
    setShowFailed(false);
    form.resetFields();
  };

  const showFailed = () => {
    setShowFailed(true);
    setShowSuccess(false);
  };

  const handleInput = (value: any) => {
    var temp = value;
    value =
      temp.indexOf(".") >= 0
        ? temp.substr(0, temp.indexOf(".")) + temp.substr(temp.indexOf("."), 5)
        : temp;
    setPurchaseAmountInput(value);
    let valueFirstPay = (parseFloat(value) * price * collateral) / 100;

    setFirstPay(valueFirstPay);
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      const res = await approve();

      if (res) {
        setRefreshAllowance(true);
        notification.open({
          message: NotificationEnum.APPROVE_SUCCESS,
        });
      } else {
        setRefreshAllowance(false);
        notification.open({
          message: NotificationEnum.APPROVE_ERROR,
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      setRefreshAllowance(false);
      notification.open({
        message: NotificationEnum.APPROVE_ERROR,
      });
    }
  };
  const handleCreateOrder = async (values: any) => {
    try {
      setLoading(true);
      const res = await createOrder(
        poolAdress,
        ethers.utils.parseUnits(
          values?.purchaseAmount,
          parseFloat(sellToken?.decimal)
        ),
        values?.term * SECONDS_PER_DAY
      );

      if (!!res?.transactionHash) {
        const rs = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Router,
          contractAddress: process.env.REACT_APP_LAYAWAY_ROUTER,
          eventName: EventType.CreatedOrder,
        });
        if (!!rs) {
          refresh();
          showSuccess();
          form.resetFields();
          setPurchaseAmountInput("");
        } else {
          showFailed();
        }
      }
      setLoading(false);
      onCancel();
    } catch (e) {
      console.log(e.message);
      toast.error(e?.reason || e?.message);
    }
    setLoading(false);
  };
  const handleCreateOrderETH = async (values: any, collateralValue) => {
    try {
      setLoading(true);
      const res = await createOrderETH(
        poolAdress,
        ethers.utils.parseUnits(
          values?.purchaseAmount,
          parseFloat(sellToken?.decimal)
        ),
        values?.term * SECONDS_PER_DAY,
        collateralValue
      );
      form.resetFields();
      setPurchaseAmountInput("");

      if (!!res?.transactionHash) {
        const rs = await postContractAction({
          transactionHash: res.transactionHash,
          contractName: ContractType.Router,
          contractAddress: process.env.REACT_APP_LAYAWAY_ROUTER,
          eventName: EventType.CreatedOrder,
        });
        if (!!rs) {
          refresh();
          showSuccess();
        } else {
          showFailed();
        }
      }
      setLoading(false);
      onCancel();
    } catch (e) {
      console.log(e.message);
      toast.error(e?.reason || e?.message);
    }
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    if (payToken?.tokenAddress !== ETH_ADDRESS) {
      if (allowanceToken) {
        handleCreateOrder(values);
      } else if (!allowanceToken) {
        handleApprove();
      }
    } else {
      handleCreateOrderETH(values, firstPayAmount);
    }
  };

  const isEnoughAmount = () => {
    return !(parseFloat(purchaseAmountInput) > parseFloat(availableSellAmount));
  };
  useEffect(() => {
    void (async () => {
      const resAllowance = await allowance();
      if (resAllowance) setAllowanceToken(Number(resAllowance));
    })();
  }, [account, chainId, refreshAllowance, poolAdress]);

  useEffect(() => {
    void (async () => {
      if (open) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const balance = await provider.getBalance(account);
        const balanceInEth = ethers.utils.formatEther(balance);
        setAvailableNumber(balanceInEth);
      }
    })();
  }, [account, chainId, open]);

  return (
    <>
      <CreateOrderModalWrapper
        visible={open}
        onCancel={onCancel}
        closable={false}
        footer={false}
      >
        <Wrapper>
          <TitleStyled>Create order</TitleStyled>
        </Wrapper>
        <WrapperContent>
          <StyledForm
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            form={form}
            labelcolor="#fff"
          >
            <FormItemInput name={["purchaseAmount"]} label="Purchase amount">
              <InputWrapper>
                <InputField
                  placeholder="0"
                  value={purchaseAmountInput || null}
                  type={"number"}
                  onKeyDown={blockInvalidChar}
                  bordered={false}
                  onChange={(e) => handleInput(e.target.value)}
                  disabled={loading}
                ></InputField>
                <LabelWrapper>{sellToken?.symbol?.toUpperCase()}</LabelWrapper>
              </InputWrapper>
            </FormItemInput>

            <FormItemSelect name={["term"]} label="Days Term" initialValue={30}>
              <SelectStyled
                bordered={false}
                dropdownStyle={{ background: "grey" }}
                disabled={loading}
                suffixIcon={
                  <SelectIcon src={iconSelect} alt="" preview={false} />
                }
              >
                <Option value={30}>30 days</Option>
                <Option value={60}>60 days</Option>
                <Option value={90}>90 days</Option>
                {/* <Option value={600 / SECONDS_PER_DAY}>10 minutes</Option> */}
              </SelectStyled>
            </FormItemSelect>
            <FormItemButton>
              <TextWrapper>
                *Note: When creating order, you will be collateral{" "}
                {ethers.utils.formatUnits(
                  firstPayAmount || 0,
                  payToken.decimal || 1
                ) || 0}{" "}
                {payToken?.symbol?.toUpperCase()}
              </TextWrapper>
              <TextWrapper>
                Available:{" "}
                {payToken.tokenAddress !== ETH_ADDRESS
                  ? formatNumberThousand(
                      +roundNumber(available, ROUND_AFTER_COMMA) || 0
                    )
                  : formatNumberThousand(
                      Number(
                        Math.round(availableNumber * ROUND_NUMBER) /
                          ROUND_NUMBER
                      )
                    )}{" "}
                {payToken?.symbol?.toUpperCase()}
              </TextWrapper>
            </FormItemButton>

            <FormItemButton>
              <ButtonBackground src={vectorButton} alt="" preview={false} />
              <Row justify="center">
                {allowanceToken || payToken?.tokenAddress === ETH_ADDRESS ? (
                  <BottomButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={
                      (payToken.tokenAddress === ETH_ADDRESS
                        ? +availableNumber
                        : available) <
                        convertBigNumber(firstPayAmount, payToken.decimal) ||
                      !Number(purchaseAmountInput) ||
                      purchaseAmountInput === "0" ||
                      !isEnoughAmount()
                    }
                  >
                    Create order
                  </BottomButton>
                ) : (
                  <BottomButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Approve
                  </BottomButton>
                )}
              </Row>
            </FormItemButton>
          </StyledForm>
        </WrapperContent>
      </CreateOrderModalWrapper>

      <SuccessModal visible={success} onCancel={onCloseModal} />
      <FailedModal visible={failed} onCancel={onCloseModal} />
    </>
  );
};
