import { useEffect, useState } from "react";
import { Row, Col, Select, Image } from "antd";
import { TextInputField } from "components/TextInputField";
import {
  FilterWrapper,
  IconToken,
  LargeTitleLabel,
  MediumTitleLabel,
  TokenRow,
} from "./style";
import { getToken, IToken } from "services/api";
import { SelectStyled2 } from "components/StyledSelect";
import select1 from "assets/vectors/vector-arrow-select-1.svg";
import select2 from "assets/vectors/vector-arrow-select-2.svg";
import { iconDefault } from "utils/defaultIcon";
import close from "assets/icons/close.png";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

const { Option } = Select;
export function PoolFilter({
  onSearch,
  searchBySellAmount,
  searchByPrice,
  searchBySellToken,
  searchByPayToken,
}) {
  const [sellTokens, setSellTokens] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [payTokens, setPayTokens] = useState([]);
  const [listSellTokens, setListSellTokens] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndexPay, setCurrentIndexPay] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPayToken, setTotalPayToken] = useState(0);
  const [pageSizePayToken, setPageSizePayToken] = useState(10);
  const [listPayTokens, setListPayTokens] = useState([]);
  const { refreshToken } = useSelector(
    (state: RootStateOrAny) => state.refreshToken
  );
  const [params, setParams] = useState<IToken>({
    pageIndex: currentPage,
    pageSize: undefined,
    search: undefined,
    tokenAddress: undefined,
  });
  const [paramsPayToken, setParamsPayToken] = useState<IToken>({
    pageIndex: currentIndexPay,
    pageSize: undefined,
    search: undefined,
    tokenAddress: undefined,
  });

  const handleSearchSellToken = async (value: string) => {
    const res = await getToken({ ...params, search: value, pageIndex: 1 });
    setCurrentPage(1);

    if (res) setListSellTokens(res?.payload?.data);
  };

  const handleSearchPayToken = async (value: string) => {
    const res = await getToken({
      ...paramsPayToken,
      search: value,
      pageIndex: undefined,
    });
    if (res) setListPayTokens(res?.payload?.data);
  };

  const handleClearToken = async () => {
    const res = await getToken({
      ...params,
      pageIndex: 1,
    });
    if (res) {
      setListSellTokens(res?.payload?.data);
      setCurrentPage(1);
    }
  };

  const handleClearPayingToken = async () => {
    const res = await getToken({
      ...paramsPayToken,
      pageIndex: 1,
    });
    if (res) {
      setListPayTokens(res?.payload?.data);
      setCurrentIndexPay(1);
    }
  };

  useEffect(() => {
    void (async () => {
      const res = await getToken({ ...params });
      if (res?.payload?.data) {
        setSellTokens(res?.payload?.data);
        setPageSize(res?.payload?.pageSize);
        // setPayTokens(res?.payload?.data);
        setTotal(res?.payload?.totalCount);
        setListSellTokens([...res?.payload?.data]);
      }
    })();
  }, [params, refreshToken]);

  useEffect(() => {
    void (async () => {
      const res = await getToken({ ...paramsPayToken });
      if (res?.payload?.data) {
        // setSellTokens(res?.payload?.data);
        setPayTokens(res?.payload?.data);
        setTotalPayToken(res?.payload?.totalCount);
        setPageSizePayToken(res?.payload?.pageSize);
        setListPayTokens([...res?.payload?.data]);
      }
    })();
  }, [paramsPayToken, refreshToken]);

  const handleScrollPayToken = async (e) => {
    const isEndOfList =
      e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight;

    if (
      listPayTokens.length < totalPayToken &&
      isEndOfList &&
      totalPayToken / pageSizePayToken >= currentIndexPay
    ) {
      const res = await getToken({
        ...paramsPayToken,
        pageIndex: currentIndexPay + 1,
      });
      if (res?.payload?.data) {
        setListPayTokens((prv) => [...prv, ...res?.payload?.data]);
      }
      setCurrentIndexPay(currentIndexPay + 1);
    }
  };

  const handleScroll = async (e) => {
    const isEndOfList =
      e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight;

    if (
      listSellTokens.length < total &&
      isEndOfList &&
      total / pageSize >= currentPage
    ) {
      const res = await getToken({ ...params, pageIndex: currentPage + 1 });
      if (res?.payload?.data) {
        setSellTokens(res?.payload?.data);
        // setPayTokens(res?.payload?.data);
        setTotal(res?.payload?.totalCount);
        setPageSize(res?.payload?.pageSize);
        setListSellTokens((prv) => [...prv, ...res?.payload?.data]);
      }
      setCurrentPage(currentPage + 1);

      // setParams({ ...params, pageIndex: currentPage + 1 });
    }
  };

  return (
    <FilterWrapper>
      <div>
        <LargeTitleLabel>FILTER</LargeTitleLabel>
        <TextInputField
          placeholder="Search..."
          style={{ marginTop: "20px" }}
          onChange={(e) => onSearch(e.target.value)}
        ></TextInputField>
      </div>

      <div>
        <MediumTitleLabel>Selling amount</MediumTitleLabel>
        <TextInputField
          placeholder="Input amount"
          type="number"
          onChange={(e) => searchBySellAmount(e.target.value)}
        ></TextInputField>
      </div>

      <Row gutter={[8, 8]}>
        <Col span={12}>
          <MediumTitleLabel>Selling token</MediumTitleLabel>
          <SelectStyled2
            showSearch
            placeholder="Select a token"
            allowClear
            optionFilterProp="children"
            onChange={searchBySellToken}
            bordered={false}
            dropdownStyle={{ background: "#000" }}
            onSearch={handleSearchSellToken}
            getPopupContainer={(trigger) => trigger.parentElement}
            suffixIcon={<Image src={select1} alt="" preview={false} />}
            onPopupScroll={handleScroll}
            onClear={handleClearToken}
          >
            {listSellTokens?.length === 0
              ? sellTokens?.map((token, index) => (
                  <Option
                    key={index}
                    style={{ color: "#000" }}
                    value={token?.tokenAddress}
                    className="select-token"
                  >
                    <IconToken
                      src={token?.img || iconDefault}
                      fallback={iconDefault}
                      alt=""
                      preview={false}
                    />
                    {token?.symbol?.toUpperCase()}
                  </Option>
                ))
              : listSellTokens?.map((token, index) => (
                  <Option
                    key={index}
                    style={{ color: "#000" }}
                    value={token?.tokenAddress}
                    className="select-token"
                  >
                    <IconToken
                      src={token?.img || iconDefault}
                      fallback={iconDefault}
                      alt=""
                      preview={false}
                    />
                    {token?.symbol?.toUpperCase()}
                  </Option>
                ))}
          </SelectStyled2>
        </Col>

        <Col span={12}>
          <MediumTitleLabel>Paying token</MediumTitleLabel>{" "}
          <SelectStyled2
            showSearch
            placeholder="Select a token"
            optionFilterProp="children"
            onChange={searchByPayToken}
            bordered={false}
            allowClear
            dropdownStyle={{ background: "#000" }}
            onSearch={handleSearchPayToken}
            getPopupContainer={(trigger) => trigger.parentElement}
            suffixIcon={<Image src={select2} alt="" preview={false} />}
            onPopupScroll={handleScrollPayToken}
            onClear={handleClearPayingToken}
          >
            {listPayTokens?.length === 0
              ? payTokens?.map((token, index) => (
                  <Option
                    key={index}
                    style={{ color: "#000" }}
                    value={token?.tokenAddress}
                    className="select-token"
                  >
                    <IconToken
                      src={token?.img || iconDefault}
                      fallback={iconDefault}
                      alt=""
                      preview={false}
                    />
                    {token?.symbol?.toUpperCase()}
                  </Option>
                ))
              : listPayTokens?.map((token, index) => (
                  <Option
                    key={index}
                    style={{ color: "#000" }}
                    value={token?.tokenAddress}
                    className="select-token"
                  >
                    <IconToken
                      src={token?.img || iconDefault}
                      fallback={iconDefault}
                      alt=""
                      preview={false}
                    />
                    {token?.symbol?.toUpperCase()}
                  </Option>
                ))}
          </SelectStyled2>
        </Col>
      </Row>

      <div>
        <MediumTitleLabel>Price</MediumTitleLabel>
        <TextInputField
          placeholder="Input price"
          type="number"
          onChange={(e) => searchByPrice(e.target.value)}
        ></TextInputField>
      </div>
    </FilterWrapper>
  );
}
