const SECONDS_PER_DAY = 60 * 60 * 24;
const SECONDS_PER_HOUR = 60 * 60;
const SECONDS_PER_MINUTE = 60;
export const convertSecondToDay = (s: number) => {
  let value: number;
  let type: string;

  if (s > SECONDS_PER_DAY) {
    value = s / SECONDS_PER_DAY;
    type = "days";
  } else if (s > SECONDS_PER_HOUR) {
    value = s / SECONDS_PER_HOUR;
    type = "hours";
  } else {
    value = s / SECONDS_PER_MINUTE;
    type = "minutes";
  }
  return { value, type };
};
