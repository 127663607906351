export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const formatNumberThousand = (value) => {
  if (value === 0) return 0;
  if (value < 0.0001) return "<0.0001";
  if (value % 1 === 0) return value?.toLocaleString("en-US");
  return value?.toLocaleString("en-US", { maximumFractionDigits: 4 });
};

export const formatThousandWithDecimal = (value, decimals) => {
  if (value === 0) return 0;
  if (value < 0.0001) return "<0.0001";
  if (value % 1 === 0) return value?.toLocaleString("en-US");
  return value?.toLocaleString("en-US", { maximumFractionDigits: decimals });
};

export const formatNumber = (numb: any) => {
  if (numb === parseInt(numb, 10)) {
    numb = Number(numb.toFixed(4));
  }

  return numb > 1e24
    ? (numb / 1e24).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "Q"
    : numb > 1e12
    ? (numb / 1e12).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "T"
    : numb > 1e9
    ? (numb / 1e9).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "B"
    : numb > 1e6
    ? (numb / 1e6).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M"
    : numb > 1e3
    ? String(numb).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : numb > 0
    ? numb
    : 0;
};
