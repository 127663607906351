import { Form, Select, Image, Row } from "antd";
import { StyledForm } from "components/StyledForm";
import { ContractType } from "constants/enum/ContractType";
import { EventType } from "constants/enum/EventType";
import useLaywayRouterCallback from "hooks/layaway-router/useLayawayRouterCallback";
import { useState, useEffect } from "react";
import { getToken, IToken } from "services/api";
import { postContractAction } from "services/api/contract_action";
import { FailedModal } from "../FailedModal";
import { SuccessModal } from "../SuccessModal";
import { notification } from "antd";
import { NotificationEnum } from "constants/enum/Notifications";
import { NULL_ADDRESS } from "constants/value";
import {
  CreatePoolModalWrapper,
  Wrapper,
  TitleStyled,
  WrapperContent,
  BottomButton,
  WrapperLabel,
  SelectWrapper,
  FormItemWrapper,
  FormItemSelect,
  FormItemInput,
  FormItemButton,
  WrapperInput,
  AddButtonWrapper,
  LineTitle,
  ButtonBackground,
  InputWrapper,
  InputField,
} from "./styled";
import { AddNewTokenModal } from "../AddNewTokenModal";
import vector from "assets/vectors/underline.svg";
import bgButton from "assets/vectors/vector-pool-button.svg";
import iconSelect from "assets/icons/select-sell-token.svg";
import iconPaySelect from "assets/icons/select-pay-token.svg";
import usePoolTokenCallback from "hooks/pool-token/usePoolTokenCallback";
import { IconToken } from "components/PoolFilter/style";
import { iconDefault } from "utils/defaultIcon";
import close from "assets/icons/close.png";
import { RootStateOrAny, useSelector } from "react-redux";
import { toast } from "react-toastify";

const { Option } = Select;
export const CreatePoolModal = ({ visible, onCancel, refresh }) => {
  const { createLayawayPool, poolExist } = useLaywayRouterCallback();
  const [sellTokens, setSellTokens] = useState([]);
  const [payTokens, setPayTokens] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setShowSuccess] = useState(false);
  const [failed, setShowFailed] = useState(false);
  const [visibleAddNewToken, setVisibleAddNewToken] = useState(false);
  const [sellSelect, setSellSelect] = useState(undefined);
  const [paySelect, setPaySelect] = useState(undefined);
  const [inputPrice, setInputPrice] = useState(undefined);
  const [form] = Form.useForm();
  const [poolInfoExist, setPoolInfoExist] = useState("");
  const { poolInfo } = usePoolTokenCallback(poolInfoExist);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndexPay, setCurrentIndexPay] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizePayToken, setPageSizePayToken] = useState(10);
  const { refreshToken } = useSelector(
    (state: RootStateOrAny) => state.refreshToken
  );

  const [total, setTotal] = useState(0);
  const [totalPayToken, setTotalPayToken] = useState(0);
  const [params, setParams] = useState<IToken>({
    pageIndex: currentPage,
    pageSize: undefined,
    search: undefined,
    tokenAddress: undefined,
  });

  const [paramsPayToken, setParamsPayToken] = useState<IToken>({
    pageIndex: currentIndexPay,
    pageSize: undefined,
    search: undefined,
    tokenAddress: undefined,
  });

  const [listSellTokens, setListSellTokens] = useState([]);
  const [listPayTokens, setListPayTokens] = useState([]);

  useEffect(() => {
    void (async () => {
      const res = await getToken({ ...params });
      if (res?.payload?.data) {
        setSellTokens(res?.payload?.data);
        // setPayTokens(res?.payload?.data);
        setTotal(res?.payload?.totalCount);
        setPageSize(res?.payload?.pageSize);
        setListSellTokens([...res?.payload?.data]);
      }
    })();
  }, [params, refreshToken]);

  useEffect(() => {
    void (async () => {
      const res = await getToken({ ...paramsPayToken });
      if (res?.payload?.data) {
        // setSellTokens(res?.payload?.data);
        setPayTokens(res?.payload?.data);
        setTotalPayToken(res?.payload?.totalCount);
        setPageSizePayToken(res?.payload?.pageSize);
        setListPayTokens([...res?.payload?.data]);
      }
    })();
  }, [paramsPayToken, refreshToken]);

  useEffect(() => {
    void (async () => {
      if (inputPrice && sellSelect && paySelect) {
        const isPoolExist = await poolExist(sellSelect, paySelect, inputPrice);
        if (isPoolExist) setPoolInfoExist(isPoolExist);
      }
    })();
  }, [inputPrice, sellSelect, paySelect]);

  const handleChangeSell = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    setSellSelect(value);
    setCurrentPage(1);
  };

  const handleChangePay = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    setPaySelect(value);
    setCurrentIndexPay(1);
  };

  const handleSearchSellToken = async (value: string) => {
    const res = await getToken({
      ...params,
      search: value,
      pageIndex: 1,
    });
    setCurrentPage(1);
    if (res) setListSellTokens(res?.payload?.data);
  };

  const handleSearchPayToken = async (value: string) => {
    const res = await getToken({
      ...paramsPayToken,
      search: value,
      pageIndex: undefined,
    });
    if (res) setListPayTokens(res?.payload?.data);
  };

  const handleClearSellToken = async () => {
    const res = await getToken({
      ...params,
      pageIndex: 1,
    });
    if (res) {
      setListSellTokens(res?.payload?.data);
      setCurrentPage(1);
    }
  };

  const handleClearPayingToken = async () => {
    const res = await getToken({
      ...paramsPayToken,
      pageIndex: 1,
    });
    if (res) {
      setListPayTokens(res?.payload?.data);
      setCurrentIndexPay(1);
    }
  };

  const onCloseModal = () => {
    setShowSuccess(false);
    setShowFailed(false);
    setVisibleAddNewToken(false);
    form.resetFields();
  };

  const showSuccess = () => {
    setShowSuccess(true);
    setShowFailed(false);
    setVisibleAddNewToken(false);
    onCancel();
  };

  const showFailed = () => {
    setShowFailed(true);
    setShowSuccess(false);
    setVisibleAddNewToken(false);
    onCancel();
  };

  const showAddNewToken = () => {
    setVisibleAddNewToken(true);
    setShowFailed(false);
    setShowSuccess(false);
    onCancel();
  };

  const handleScroll = async (e) => {
    const isEndOfList =
      e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight;

    if (
      listSellTokens.length < total &&
      isEndOfList &&
      total / pageSize >= currentPage
    ) {
      const res = await getToken({ ...params, pageIndex: currentPage + 1 });
      if (res?.payload?.data) {
        setListSellTokens((prv) => [...prv, ...res?.payload?.data]);
      }
      setCurrentPage(currentPage + 1);
    }
  };

  const handleScrollPayToken = async (e) => {
    const isEndOfList =
      e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight;

    if (
      listPayTokens.length < totalPayToken &&
      isEndOfList &&
      totalPayToken / pageSizePayToken >= currentIndexPay
    ) {
      const res = await getToken({
        ...paramsPayToken,
        pageIndex: currentIndexPay + 1,
      });
      if (res?.payload?.data) {
        setListPayTokens((prv) => [...prv, ...res?.payload?.data]);
      }
      setCurrentIndexPay(currentIndexPay + 1);
    }
  };

  const handleCreatePool = async (values) => {
    if (sellSelect === paySelect) {
      setLoading(false);
      toast("Sell token and paying token must not be the same!!");
    } else {
      try {
        const res = await createLayawayPool(
          sellSelect,
          paySelect,
          values?.price
        );

        if (!!res?.transactionHash) {
          const rs = await postContractAction({
            transactionHash: res.transactionHash,
            contractName: ContractType.Router,
            contractAddress: process.env.REACT_APP_LAYAWAY_ROUTER,
            eventName: EventType.CreatedLayawayPool,
          });
          setLoading(false);
          form.resetFields();
          setPaySelect(undefined);
          setSellSelect(undefined);

          if (!!rs) {
            showSuccess();
            refresh();
          } else {
            form.resetFields();
            setSellSelect(undefined);
            setPaySelect(undefined);
            showFailed();
          }
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e?.reason || e?.message);
      }
      setLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    if (values) {
      if (values?.price <= 0) {
        setLoading(false);
        notification.open({
          message: "Price must be greater than 0!!",
        });
      } else {
        try {
          setLoading(true);
          const isPoolExist = await poolExist(
            sellSelect,
            paySelect,
            values?.price
          );

          const isOldPool = await poolInfo();
          if (isPoolExist === NULL_ADDRESS) {
            handleCreatePool(values);
          } else {
            if (!isOldPool.open) {
              handleCreatePool(values);
            } else {
              setLoading(false);
              notification.open({
                message: NotificationEnum.POOL_EXIST,
              });
            }
          }
        } catch (e) {
          console.log(e.message);
          setLoading(false);
        }
      }
    }
  };

  const handleInputChange = (value: any) => {
    let temp = value;
    value =
      temp.indexOf(".") >= 0
        ? temp.substr(0, temp.indexOf(".")) + temp.substr(temp.indexOf("."), 5)
        : temp;
    setInputPrice(value);
  };

  return (
    <>
      <CreatePoolModalWrapper
        visible={visible}
        onCancel={onCancel}
        closable={false}
        footer={false}
      >
        <Wrapper>
          <TitleStyled>Create pool</TitleStyled>
          <LineTitle src={vector} alt="" preview={false} />
        </Wrapper>
        <WrapperContent>
          <StyledForm
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            labelcolor="#fff"
            form={form}
          >
            <FormItemWrapper>
              <FormItemSelect name={["sellToken"]} label="Sell token">
                <SelectWrapper
                  showSearch
                  allowClear
                  placeholder="Select a token"
                  optionFilterProp="children"
                  bordered={false}
                  onChange={handleChangeSell}
                  disabled={loading}
                  dropdownStyle={{ background: "#d9d9d9" }}
                  onSearch={handleSearchSellToken}
                  suffixIcon={<Image src={iconSelect} alt="" preview={false} />}
                  onPopupScroll={handleScroll}
                  clearIcon={<Image src={close} alt="" preview={false} />}
                  onClear={handleClearSellToken}
                >
                  <Option className="button-add">
                    <AddButtonWrapper onClick={showAddNewToken}>
                      Create new token
                    </AddButtonWrapper>
                  </Option>
                  {listSellTokens?.length === 0
                    ? sellTokens?.map((token, index) => (
                        <Option
                          key={index}
                          style={{ color: "#000" }}
                          value={token?.tokenAddress}
                          className="select-token"
                        >
                          <IconToken
                            src={token?.img || iconDefault}
                            fallback={iconDefault}
                            alt=""
                            preview={false}
                          />
                          {token?.symbol?.toUpperCase()}
                        </Option>
                      ))
                    : listSellTokens?.map((token, index) => (
                        <Option
                          key={index}
                          style={{ color: "#000" }}
                          value={token?.tokenAddress}
                          className="select-token"
                        >
                          <IconToken
                            src={token?.img || iconDefault}
                            fallback={iconDefault}
                            alt=""
                            preview={false}
                          />
                          {token?.symbol?.toUpperCase()}
                        </Option>
                      ))}
                </SelectWrapper>
              </FormItemSelect>

              <FormItemSelect name={["payToken"]} label="Paying token">
                <SelectWrapper
                  showSearch
                  allowClear
                  placeholder="Select a token"
                  optionFilterProp="children"
                  bordered={false}
                  onChange={handleChangePay}
                  disabled={loading}
                  dropdownStyle={{ background: "#d9d9d9" }}
                  onSearch={handleSearchPayToken}
                  suffixIcon={
                    <Image src={iconPaySelect} alt="" preview={false} />
                  }
                  onPopupScroll={handleScrollPayToken}
                  clearIcon={<Image src={close} alt="" preview={false} />}
                  onClear={handleClearPayingToken}
                >
                  <Option className="button-add">
                    <AddButtonWrapper onClick={showAddNewToken}>
                      Create new token
                    </AddButtonWrapper>
                  </Option>

                  {listPayTokens?.length === 0
                    ? payTokens?.map((token, index) => (
                        <Option
                          key={index}
                          style={{ color: "#000" }}
                          value={token?.tokenAddress}
                          className="select-token"
                        >
                          <IconToken
                            src={token?.img || iconDefault}
                            fallback={iconDefault}
                            alt=""
                            preview={false}
                          />
                          {token?.symbol?.toUpperCase()}
                        </Option>
                      ))
                    : listPayTokens?.map((token, index) => (
                        <Option
                          key={index}
                          style={{ color: "#000" }}
                          value={token?.tokenAddress}
                          className="select-token"
                        >
                          <IconToken
                            src={token?.img || iconDefault}
                            fallback={iconDefault}
                            alt=""
                            preview={false}
                          />
                          {token?.symbol?.toUpperCase()}
                        </Option>
                      ))}
                </SelectWrapper>
              </FormItemSelect>
            </FormItemWrapper>

            <FormItemInput className="mt-4" name={["price"]} label="Price">
              <InputWrapper>
                <InputField
                  suffix={
                    listPayTokens?.find((o) => o.tokenAddress === paySelect)
                      ?.symbol
                  }
                  type={"number"}
                  value={inputPrice || null}
                  placeholder="0"
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                  disabled={loading}
                ></InputField>
              </InputWrapper>
            </FormItemInput>

            <FormItemButton>
              <ButtonBackground src={bgButton} alt="" preview={false} />
              <Row justify="center">
                <BottomButton
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !inputPrice ||
                    Number(inputPrice) <= 0 ||
                    !sellSelect ||
                    !paySelect
                  }
                >
                  Create pool
                </BottomButton>
              </Row>
            </FormItemButton>
          </StyledForm>
        </WrapperContent>
      </CreatePoolModalWrapper>
      <AddNewTokenModal visible={visibleAddNewToken} onCancel={onCloseModal} />
      <SuccessModal visible={success} onCancel={onCloseModal} />
      <FailedModal visible={failed} onCancel={onCloseModal} />
    </>
  );
};
